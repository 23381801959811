export default {
  global:{
    cancel:'取消',
    next:'下一步',
    confirm:'确认',
    Copy:'复制',
    Rewards:'奖励',
    Password:'密码',
    Close:'关闭',
    No:'否',
    All:'全部',
    Yes:'是',
    Successful:'成功',
    Done:'完成',
    Save:'保存',
    SEND:"发送",
    comingSoon:'敬请期待!',
    DAY:'天',
    HOUR:'时',
    MIN:'分',
    SEC:'秒',
    Residue:'剩余',
    BUY:'购买',
    BUYED:'不可购买',
    PlatinumMember:'白金卡',
    GlobalPartner:'全球合作伙伴',
    Civilian:'平民',
    copySucc:'复制成功!',
    Exchange:'兑换',
    noRecords:'即将上线',
    useDappExplorer:'请使用Dapp浏览器访问,或刷新页面重试',
    changeAccount:'您已将账户切换至',
    referrals:"人",
    prevPage:"上一页",
    nextPage:"下一页",
    total1:"共",
    total2:"条",
    soldOut:"售罄",
    MAX:'最大',
    placeholderInit:'请输入',
    Authorize:'授权',
    APR:'收益:',
    Release:'释放',
    Pledge:'质押',
    Token:'Token',
    Receive:'接收',
    unStake:'解除质押',
    Stake:'质押',
    Withdraw:'收益提取',
    rewardsHistory:'收益提取历史',
    investHistory:'算力购买历史',
    moreStake:'质押',
    submit:'提交',
    Fund:'资金',
    BSC:'BSC',
    Records:"条记录",
    Insufficient:'余额不足',
    AmountCannot:'输入不能为0',
    AmountTooBig:'输入不能小于100',
    exceedAmount:"兑换数量不能超出您的数量",
    NoInsufficient:"没有可提取的收益",
    RewardInsufficient:'暂无收益',
    usdtInsufficient:'USDT 余额不足',
    beenSent:'已发送',
    confirmed:'已确认',
    failed:'失败',
    approveTimeout:'授权额度合约超时，请重新发起操作',
    InsufficientApproval:'授权金额不足，请增加授权额度',
    contractTimeout:'合约执行结果查询超时，您可稍后查询操作结果，以免重复投资',
    createTime:'创建时间',
    amount:"金额",
    incomeAmountHistory:'收益提取历史',
    ExchangeSuc:'恭喜，兑换成功!',
    usdtBalance:"USDT余额",
    signTitle:"提示",
    signContent:'需要授权签名来进行安全访问',
    signBtn:'申请签名',
    signLoadingText:'正在执行签名操作，请勿刷新页面',
    noticeText:' SWAP 开盘通知'
  },
  menu:{
    Home:'首页',
    CardPurchase:'白金卡牌',
    PersonalCenter:'个人中心',
    whitePaper:'白皮书',
    ExtractRecords:'提取记录',
    LsdPledge:'LSD 质押',
    exchangeGains:'收益兑换',
    regulatoryAudit:'监管审计',
    superNodeRanking:'超级节点排名',
    earnings:'收益提取',
    earningsHistory:'收益提取历史',
    pancakeStake:'外盘质押'
  },
  home:{
    globalTitle:" Swap 全球公开上线",
    Lunar:" 探月白金卡",
    ExplorationPlatinumCards:'抢购中',
    list1:'兑换算力获取百倍收益',
    list2: '五种获取奖励方式 各显其能' ,
    list3: '先到先得，数量有限',
    BUYNOW:'开始挖矿',
    PriceTrends:' 价格趋势',
    days3:"3天",
    days5:"5天",
    days7:"7天",
    days14:"15天",
    inviteFriend:'邀请好友',
    copyLink:'复制链接',
    join:'加入生态',
    Currentamount:'矿池余额',
    MintPool:'矿池USDT余额',
    Totalflux:'流通量',
    Accumulatedruin:'累计销毁',
    countdown:"矿池启动倒计时",
    Marketvalue:'市值',
    LPMining:'LP挖矿',
    LSDMining:'LSD质押',
    ExchangeGains:'收益兑换',
    RegulatoryAudit:'监管审计 ',
    CommunityBenefits:'团队收益',
    PersonalCenter:'个人中心',
    Binding:'提交',
    ReferralCode:'推荐码',
    referalPlaceHolder:'请输入推荐者的钱包地址',
    DefaultofficialReferralCode:'填入官方默认推荐码',
    BindSucc:'绑定推荐码成功!',
    BindDuplicate:'您已绑定推荐码，不可重复绑定!',
    referralCodeEmpty:'推荐码不可为空!',
    referralCodeFormat:'推荐码格式有误，请仔细核对！',
    referSelf:'不能填写自己的推荐码，请核实后重试！',
    welcomeJoin:'欢迎加入社群，请先加入生态，绑定您的推荐人！',
    currentPrice:'实时价格:',
    arriveOver:'您即将达到5倍收益上限，将自动出局',
    arriveOut:'您已经达到5倍收益上限，已自动出局',
    continueInvest:"继续投资",
    loading:'合约交易正在执行，请不要刷新页面',

  },
  ido:{
    idoSucc:'您的交易已完成，请在区块浏览器上检查您的交易详情。',
    idoError:'交易执行失败，请在区块浏览器上检查您的交易,或联系项目团队寻求支持。',
  },
  bottom:{
    welcome:'欢迎加入我们',
    copyright:' 版权所有，保留所有权利',
  },
  web3:{
    ACTION_REJECTED:"您已取消交易",
    UNPREDICTABLE_GAS_LIMIT:'燃料费不足',
    err32000:'燃料费不足',
    CALL_EXCEPTION:"执行异常，请稍后重试",
    INVALID_ARGUMENT:"合约执行失败，参数错误"
  },
  error:{
    ERR_NETWORK:"网络异常!"
  }
}
