import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/overview',
    name: 'overview',
    meta:{
      title:'资产管理',
    },
    component: () => import(/* webpackChunkName: "overview" */ '../views/overview.vue')
  },

  {
    path: '/transfer',
    name: 'transfer',
    meta:{
      title:'交易记录',
    },
    component: () => import(/* webpackChunkName: "transfer" */ '../views/transfer.vue')
  },

  {
    path: '*',
    redirect: '/'
  }
]


//解决vue-router重复点击报错问题（this.$router.push()）
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push= function push(location) {
  return originalPush.call(this, location).catch(err => err);
};


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
