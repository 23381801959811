/*
 * @Author: 1 1
 * @Date: 2022-10-14 13:32:47
 * @LastEditors: 1 1
 * @LastEditTime: 2022-11-10 18:50:27
 * @FilePath: /ones-ph-web/src/lang/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import enLocale from './en'
import zhLocale from './zh'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    // ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    // ...elementZhLocale
  },

}
export function getLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  console.log(language, 'console.log(language)')
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      Cookies.set('language',locale)
      return locale
    }
  }
  return 'zh'
}
// console.log(getLanguage(), '000000000')
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale:getLanguage(),
  // set locale messages
  messages
})

export default i18n
