import LoadingVue from '@/components/Loading.vue'
import i18n from '@/lang'
import store from '@/store'
const $loading = {
  install: (Vue) => {
    // 通过 Vue.extend方法 获取LoadingComponent 组件 类
    const LoadingComponent = Vue.extend({
      extends: LoadingVue,
      i18n, // 将 i18n 传递给扩展的组件
      store
    });
    // new LoadingComponent得到组件的实例
    const vm = new LoadingComponent();
    // 获取组件实例的html 并插入到body中
    const tpl = vm.$mount().$el;
    // 插入到body中
    document.body.appendChild(tpl);
    // 添加 显示loading方法
    Vue.prototype.$showLoading = (conf) => {
      // 通过改变实例 .mask v-show绑定变量控制显示
      if(conf){
        vm.msg=conf.msg
        if(vm.mask!=null){
          vm.mask=conf.mask
        }
      }
      vm.isShow = true
    }
    // 添加关闭loading方法
    Vue.prototype.$hideLoading = () => {
      // 通过改变实例 .mask v-show绑定变量控制隐藏
      vm.msg=""
      vm.isShow = false
    }
  }
}

export default $loading;