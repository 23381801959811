import Msg from '@/components/Msg.vue'
import Vue from "vue";
const vm = new Vue();

let instance;           //创建myLayer组件的一个实例
export default {
  install(Vue, options) {
    if (!instance) {
      const layerConstructor = Vue.extend(Msg);
      instance = new layerConstructor({
        el: document.createElement('div')
      });
      document.body.appendChild(instance.$el);
    }

    instance.visible = false;

    let layer = {
      success(msg = '操作成功',time=3000,options={show:false}) {
        vm.$hideLoading();
        instance.message = msg;
        instance.type = 'success';
        instance.visible = true;
        instance.configOptions =  options
        setTimeout(() => {
          instance.visible = false;
        }, time);
      },
      loading(msg = '请稍后...') {
        instance.message = msg;
        instance.type = 'loading';
        instance.visible = true;
      },
      error(msg = '操作失败',time=5000,mask= true) {

        vm.$hideLoading();
        instance.message = msg;
        instance.type = 'error';
        instance.visible = true;
        instance.mask = mask;
        // instance.setMask(mask)
        if(time!=-1){
          setTimeout(() => {
            //vm.setMaskVisible(false)
            instance.visible = false;
          }, 5000);
        }
      },
      warning(msg = '警告',time=3000,mask= false) {
        vm.$hideLoading();
        instance.message = msg;
        instance.type = 'warning';
        instance.visible = true;
        instance.mask = mask;
        //vm.setMaskVisible(false)
        setTimeout(() => {
          instance.visible = false;
          //vm.setMaskVisible(false)
        }, time);
      },
      confirm(conf, callBack) {
        instance.message = conf.msg;
        instance.title = conf.title;
        instance.yes=conf.yes
        instance.type = 'confirm';
        instance.callBack = callBack;
        instance.visible = true;
      },
      message(msg = '普通消息') {
        vm.$hideLoading();
        instance.message = msg;
        instance.type = 'message';
        instance.visible = true;
        setTimeout(() => {
          instance.visible = false;
        }, 3000);
      },
      close() {
        instance.visible = false;
      },
    };

    if (!Vue.$msg) {
      Vue.$msg = layer;
    }

    Vue.mixin({
      created() {
        this.$msg = Vue.$msg;
      }
    });
  }
}
//https://blog.51cto.com/rahmanjooon/5651731