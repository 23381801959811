<template>
    <!--
        mask是loading的背景 v-show控制loading显示消失
    -->
    <div class="mask1" v-show="isShow">
        <div class="mask-box">
            <div class="text" v-if="!isLoadingText && !isLoadingSign && !isTransitionLoading">Please confirm the transaction and sign on your wallet. Please do not close or refresh current page until the transaction is completed</div>
            <!-- <div class="text-transion" v-if="isTransitionLoading">{{ isTransitionLoading }}</div>  -->
            <div class="loading-icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="loading-icon-img" width="45" height="44" viewBox="0 0 45 44" fill="none">
                    <path d="M2.50024 22.0005C2.50024 33.0462 11.4545 42.0005 22.5002 42.0005C33.5459 42.0005 42.5002 33.0462 42.5002 22.0005C42.5002 10.9548 33.5459 2.00049 22.5002 2.00049" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                <!-- <img :src="require('@/assets/comp/loading-icon.png')" alt="" class="loading-icon-img" /> -->
            </div>
            <div class="text-sign" v-if="isLoadingSign">Signing in progress, please do not refresh the page</div>

        </div>

        <!-- <div class="loading" v-if="false"></div>
        <div class="pots" v-if="false">
            <div class="pot"></div>
            <div class="pot"></div>
            <div class="pot"></div>
            <div class="pot"></div>
        </div>
        <div class="text" v-if="!isLoadingSign">{{ $t('global.signLoadingText') }}</div>
        <div class="msg" v-if="msg">{{ msg }}</div>-->
    </div>
</template>
<script>
export default {
    data () {
        return {
            // loading默认不显示
            isShow: false,
            msg:null,
            mask:false,
            isLoadingText:false
        }
    },

    computed: {
        isLoadingSign(){
            return this.$store.getters.isStateLoadingSing
        },
        isTransitionLoading(){
            return this.$store.getters.isTransitionLoading
        }
    },


}
</script>
<style lang="scss">
// 定义动画 控制 loading旋转




.mask1{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
    backdrop-filter: blur(5px);
    z-index: 997;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text{
        margin-bottom: 32px;
        color: #fff;
        padding:0 24px;
        letter-spacing: 0.084px;
    }
    .text-sign{
        margin-top:16px;
        color: #fff;
        padding:0 16px;
    }
    .loading{
        width: 41px;
        background-size: 41px 41px;
        height: 41px;
        //border: 4px solid rgb(219, 140, 13);
        //border-radius: 21px;
        border-left-color:transparent;
        //animation: rotate 1000ms infinite;
    }
    .pots{
        display: flex;
        gap: 5px;
        .pot{
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #6BFC9F;
            opacity: 0.3;
            margin-top: 11px;
            animation: fade 1.2s infinite;
        }
        .pot:nth-child(1) {
            animation-delay: 0s;
        }

        .pot:nth-child(2) {
            animation-delay: 0.3s;
        }

        .pot:nth-child(3) {
            animation-delay: 0.6s;
        }

        .pot:nth-child(4) {
            animation-delay: 0.9s;
        }

        @keyframes fade {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0.3;
            }
        }
    }
    .msg{
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #51FFA8;
        line-height: 21px;
        text-shadow: 0px 2px 14px rgba(0,0,0,0.5);
        text-align: center;
        margin-top: 20px;
        padding: 0 20px;

    }

}
.mask-box{
    display: flex;
    width: 525px;
    height: 206px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
    background: #0F0F16;
}
.loading-icon{
    width: 48px;
    height: 48px;
}
.loading-icon-img{
    width: 100%;
    height: 100%;
    animation: circle infinite 2s linear;
}

.text-transion{
    width: calc(100% - 48px);
    margin:0 auto;
    color:#fff;
}
// 转转转动画
@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: (768px - 1px)) {
    .mask-box{
        width: calc(100% - 32px);
        margin:0 auto;
    }
}
</style>
