import {ethers} from "ethers";
import Vue from "vue";
import {globalConfig} from "@/conf/globalConfig";
const vm = new Vue();
export const Provider =async () => {

    if (window.ethereum !== undefined) {
      return new ethers.providers.Web3Provider(window.ethereum)
    } else {
       console.log('error==>')
      vm.$msg.error("ERROR")
    }
}
export const getAccount =async () =>{
  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
    .catch((err) => {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.log('Please connect to MetaMask.');
      } else {
        console.error(err);
      }
    });
  // alert(accounts.length)
  if(accounts){
    return accounts[0]
  }else{
    return null
  }
}
export const Singer = async (hexChainId) => {
    try {
      let provider = await Provider()
      return provider.getSigner()
    } catch (e) {
      console.log('err=>>>',e)
    }
}



async function switchToMetabitMainnnet() {
  console.log("switchToMetabitMainnnet")
  let chainId = globalConfig.metabit.networkId
  console.log(chainId,'chainId')
  if(window.ethereum){
     await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: ethers.utils.hexValue(chainId) }]
    });

  }

}

// 添加币安测试网络
async function addMetabitMainnnet() {
  console.log("addMetabitMainnnet")
  let rpc=globalConfig.metabit.rpcUrl
  let explorer=globalConfig.metabit.blockExplorer
  let chainId=globalConfig.metabit.networkId
  let name=globalConfig.metabit.name
  let chainName=globalConfig.metabit.name
  let symbol=globalConfig.metabit.nativeTokenSymbol
  let decimals=globalConfig.metabit.decimals
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [{
      chainId: ethers.utils.hexlify(chainId),
      chainName: chainName,
      nativeCurrency: {
        name: name,
        symbol: symbol,
        decimals: Number.parseInt(decimals)
      },
      rpcUrls: [rpc],
      blockExplorerUrls: [explorer]
    }]
  });
}
export async function getCurrentNetworkId() {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const network = await provider.getNetwork();
  return network.chainId;
}
// 判断是否存在币安网络
async function hasBinanceTestNetwork() {
  let chainId=globalConfig.metabit.networkId

  const networkList = await ethereum.request({
    method: 'wallet_getConnectedChain'
  });

  return networkList === chainId;
}

// 执行功能
export const smartChangeNetwork = async () => {
  const  chainId = await getCurrentNetworkId()
  const defaultChainId = globalConfig.metabit.networkId
  if(chainId == defaultChainId){
    //当前链与币安链相同时，跳过切链逻辑
    return
  }
  try {

    await switchToMetabitMainnnet();

  } catch (e) {
    await addMetabitMainnnet();
    await switchToMetabitMainnnet();

  }

}

export const addToken = async (address,symbol,decimal) => {
  if (typeof window.ethereum !== "undefined") {

    const tokenAddress = address;
    const tokenSymbol = symbol;
    const tokenDecimals = decimal;
    const tokenImage ="";

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const parseToken = (val,decimal) => {
    return ethers.utils.parseUnits(val, decimal);
}
export const formatToken= (val,decimal) => {
  return ethers.utils.formatUnits(val, decimal);
}

export function formatAddress(val,num=5) {
  if (!val) {
    return '-'
  }
  let length = val.length;
  let start = val.substring(0, num)
  let end = val.substring(length - num, length)
  return `${start}...${end}`
}
export const RetainDecimal = (val, size = 4) => {
  if (!val||val==='null') return '0'
  if (typeof val === 'string') {
    val = +val
  }
  return parseInt(val*Math.pow(10,size))/Math.pow(10,size)
}


export const formatEther = (val) => {
  let formatEther = ethers.utils.formatEther(val)
  if (typeof formatEther === 'string') {
      formatEther = +formatEther
  }
  return formatEther
}

export const debounce = (func, delay) => {
  let timer = null;
  return function(...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
