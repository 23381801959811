export default {
  global:{
    cancel:'Cancel',
    next:'Next',
    confirm:'Confirm',
    Copy:'Copy',
    Rewards:'Rewards',
    Password:'Password',
    Close:'Close',
    No:'No',
    All:'All',
    Yes:'Yes',
    Successful:'Successful',
    Done:'Done',
    Save:'Save',
    SEND:"SEND",
    comingSoon:'Coming soon!',
    DAY:'DAY',
    HOUR:'HOUR',
    MIN:'MIN',
    SEC:'SEC',
    Residue:'Remaining',
    BUY:'BUY',
    BUYED:'Not for sale',
    PlatinumMember:'Platinum Member',
    GlobalPartner:'Global Partner',
    Civilian:'Civilian',
    copySucc:'Copy Success!',
    Exchange:'Exchange',
    noRecords:'No records',
    useDappExplorer:'Please install the Metamask plugin, or open with Metamask\'s DAPP browser.',
    changeAccount:'You change account to',
    referrals:"referrals",
    prevPage:"Prev",
    nextPage:"Next",
    total1:"",
    total2:" records",
    soldOut:"Sold out",
    MAX:'MAX',
    placeholderInit:'input amount',
    Authorize:'Authorize',
    APR:'APR:',
    Release:'Release',
    Pledge:'Pledge',
    Token:'Token',
    Receive:'Receive',
    unStake:'Unstake',
    Stake:'Stake',
    Withdraw:'Withdraw',
    rewardsHistory:'earnings history',
    investHistory:'Purchase history',
    moreStake:'Stake',
    submit:'submit',
    Fund:'Fund',
    BSC:'BSC',
    Records:"Records",
    bbgInsufficient:'Insufficient BBG balance',
    BBGAmountCannot:'Input BBG cannot be 0',
    BBGAmountTooBig:'Enter BBG cannot be less than 100',
    exceedBBGAmount:"The redemption amount cannot exceed your BBG amount",
    bbgNoInsufficient:"No BBG earnings to withdraw",
    bbgRewardInsufficient:'No income yet',
    usdtInsufficient:'Insufficient USDT balance',
    beenSent:'Has been sent',
    confirmed:'confirmed',
    failed:'failed',
    approveTimeout:'The authorization limit contract has timed out, please reinitiate the operation.',
    InsufficientApproval:'The authorization amount is insufficient, please increase the authorization amount',
    contractTimeout:'The contract execution result query has timed out. You can query the operation results later to avoid repeated investment',
    createTime:'create time',
    amount:"Amount",
    incomeAmountHistory:'Deposit history',
    bbgExchangeSuc:'Congratulations, BBG redemption is successful!',
    usdtBalance:"USDT Balance",
    signTitle: "Prompt",
    signContent: 'Authorization signature is required for secure access',
    signBtn: 'Request Signature',
    signLoadingText: 'Signing in progress, please do not refresh the page',
    loading:'Transaction in progress, please do not refresh the page',

  },
  menu:{
    Home:'Home',
  },
  home:{
    ExplorationPlatinumCards:'On Sale Now',
    list1:'Exchange Computing Power for 100x BBG Returns',
    list2: 'Five Reward Methods, Each with its Own Strengths' ,
    list3: 'First come, first served',
    BUYNOW:'BUY NOW',
    BBGPriceTrends:'BBG price trends',
    days3:"3D",
    days5:"5D",
    days7:"7D",
    days14:"15D",
    inviteFriend:'Invite Friend',
    copyLink:'Share link',
    joinBbg:'Join BBG',
    Currentamount:'Current amount',
    MintPool:'USDT Pool Amount',
    Totalflux:'Circulation',
    Accumulatedruin:'Accumulated ruin',
    countdown:"LP Mining Countdown",
    Marketvalue:'Market Capitalization',
    LPMining:'LP Mining',
    LSDMining:'LSD Staking',
    ExchangeGains:'Exchange Gains',
    RegulatoryAudit:'Supervision Audit',
    CommunityBenefits:'Community Promotion',
    PersonalCenter:'Personal Center',
    Binding:'Binding',
    ReferralCode:'Referral Code',
    referalPlaceHolder:'Input referral wallet address',
    DefaultofficialReferralCode:'Default official Referral Code',
    BindSucc:'Bind referral code successfully!',
    BindDuplicate:'You have binded a Referral Code Already!',
    referralCodeEmpty:'Referral code cannot be empty!',
    referralCodeFormat:'Referral code format incorrect!',
    referSelf:'You can not invite yourself!',
    welcomeJoin:'Welcome to join BBG community, please bind your referral code first！',
    arriveOver:'You are about to reach the 5 times income limit and will be automatically eliminated',
    arriveOut:'You have already reached the 5x profit limit and will be automatically withdrawn',
    continueInvest:"Continue investing"
  },
  ido:{
    idoSucc:'Your transaction is completed, please check your transaction detail on block explorer.',
    idoError:'"Transaction execution failed, please check your transaction on the block explorer, or contact the project team for support."',
  },
  bottom:{
    welcome:'Welcome to join us',
    copyright:'Copyright by The BBG, all rights reserved',
  },
  web3:{
    ACTION_REJECTED:"User rejected transaction",
    UNPREDICTABLE_GAS_LIMIT:'Out of gas limit',
    err32000:'Transaction underpriced',
    CALL_EXCEPTION:"Execution Exception, Please Try Again Later",
    INVALID_ARGUMENT:"Contract execution failed due to invalid argument."

  },
  error:{
    ERR_NETWORK:"Network Error!"
  }
}
