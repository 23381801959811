/*
 * @Author: 1 1
 * @Date: 2023-02-07 16:12:00
 * @LastEditors: 1 1
 * @LastEditTime: 2023-03-16 22:39:56
 * @FilePath: /node_staking/src/store/getters.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const getters = {
    //app全局控制
    device: state => state.app.device,
    openNav: state => state.app.openNav,

    language: state=>state.app.language,
    isLoading:state=>state.user.isLoading,
    userInfo:state=>state.app.userInfo,
    account:state=>state.app.account || localStorage.getItem('account'),
    sysParam:state=>state.app.sysParam,
    maskVisible:state=>state.app.maskVisible,
    accountInformation:state=>state.app.accountInformation,
    accessToken:state=>state.app.accessToken,
    isSign:state=>state.app.isSign,
}
export default getters
