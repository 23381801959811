<template>
  <div class="my-pagination">
    <span class="pagination-total" >
      <a href="" class="total-text">{{ totalPage }} </a> {{$t('global.Records')}}
    </span>
    
    
    <span class="pagination-layout" v-if="false">
       Each Page:
       <span class="layout-wrap" @click="pageHandle"  ref="paginationRef">
         {{ pageCurSize }}
        <van-icon name="arrow-down" size="14" color="#fff" class="arrow-down"/>
       </span>
       <ol class="pagination-list" v-if="isLayout">
        <li class="pagination-list-item" v-for="(item,index) in layoutList" :key="index" @click="()=>changeDropDown(item)" :class="pagesize==item?'pagination-list-item-active':''">{{ item }}</li>
       </ol>
    </span>
    <a class="pre-page" href="javascript:;" :class="{ disabled: currentPage === 1 }" @click="changePage(false)">
      <van-icon name="arrow-left" />
    </a>
    <span v-if="currentPage > 3">...</span>
    <a
      href="javascript:;"
      v-for="item in list"
      :key="item"
      :class="{ active: currentPage === item }"
      @click="changePage(item)"
      >{{ item }}</a
    >
    <span v-if="currentPage < pages - 2">...</span>
    <a class="next-page next-page-item" href="javascript:;" :class="{ disabled: currentPage === pages }" @click="changePage(true)">
      <van-icon name="arrow" />
    </a>
  </div>
</template>
<script>

export default {
  name: 'MyPagination',
  props: {
    totalPage: {
      type: Number,
      default: 0
    },
    current:{
      type: Number,
      default: 1
    },
    pagesize: {
      type: Number,
      default: 10
    },
    // layoutList:{
    //   type: Number,
    //   default: 5
    // },
  },
  data(){
    return {
      currentPage:this.current,
      layoutList:[10,20,30,40,50],
      isLayout:false,
      pageCurSize:this.pagesize,
      isMobile:false,
    }
  },
  computed:{
      pages(){
          return  Math.ceil(this.totalPage / this.pagesize)
      },
      list(){
        console.log(this.totalPage,'this.totalPage',this.pagesize)
          const currentPage = this.currentPage
          const pages = Math.ceil(this.totalPage / this.pagesize)
          const result = []
          // 总页数小于等于5页的时候
          if (pages <= 5) {
          for (let i = 1; i <= pages; i++) {
              result.push(i)
          }
          } else {
          // 总页数大于5页的时候
          // 控制两个极端那边的省略号的有无，页码的显示个数与选中页码居中
          if (this.currentPage <= 2) {
              for (let i = 1; i <= 5; i++) {
              result.push(i)
              }
          } else if (this.currentPage >= 3 && this.currentPage <= pages - 2) {
              for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
              result.push(i)
              }
          } else if (this.currentPage > pages - 2) {
              for (let i = pages - 4; i <= pages; i++) {
              result.push(i)
              }
          }
          }
          console.log(result,'result')
          return result
      }
  },
  mounted(){
    const isInitMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if (isInitMobile) {
          this.isMobile = true
      }
    document.addEventListener("click", e => {
          if (this.$refs.paginationRef) {
              let self = this.$refs.paginationRef.contains(e.target);
              if (!self) {
                  this.isLayout = false;
              }
          }
        

      })
  },
  methods:{
      changePage(type){
           // 点击上一页按钮
          //  const currentPage = this.currentPage
           console.log('type')
         if (type === false) {
             if (this.currentPage <= 1) return
             this.currentPage--
          } else if (type === true) {
            // 点击下一页按钮
          
              if (this.currentPage >= this.pages) return
              this.currentPage++
          } else {
            // 点击页码
            this.currentPage = type
          }
          this.$emit('change-page',  this.currentPage)
          // this.$attrs("update:goodCurrentPage",this.currentPage)
        
      },
      pageHandle(){
        this.isLayout = !this.isLayout
      },
      changeDropDown(item){
        this.isLayout = false
        this.pageCurSize = item
        console.log(item,'item')
        this.$emit('change-page',null,{perPage:item})
      }
  }
 
}
</script>
<style scoped lang="less">
.pagination-total{
color: #000 ;
}
.total-text{
color: #000 ;
}
.my-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom:0;
  > a {
    padding: 0 4px;
    // background: #fff;
    vertical-align: top;
    display: inline-block;
    font-size: 13px;

    height: 28px;
    line-height: 28px;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    margin: 0 5px;
    // background-color: #f4f4f5;
    color: #000;
    min-width: 30px;
   
    border-radius: 2px;
    &:hover {
      color: #000 ;
    }
    &.active {
      background-color: #f0f2f5;
      color: #000;
      font-weight: bold;
      // border-color: #f4f4f5;
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
      &:hover {
        color: #c0c4cc;
        
      }
    }
  }
  > span {
    margin-right: 6px;
  }
}
.pagination-layout{
  display: flex;
  position: relative;
  // width: 185px;
}
.layout-wrap{
  display: flex;
  width: calc(95px - 24px);
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding:0 12px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid  #1A2024;
  background:  #1A2024;
  margin-left:5px;

}

.pagination-list{
  position: absolute;
  top: 45px;
  right: 0;
  width: 95px;
  padding:5px 0;
  border-radius: 8px;
  background: #474754;
  // border:1px solid #191B25;
  z-index:111;
}
.pagination-list::before {
content: "";
position: absolute;
top: -19px;
right: 10px;
transform: translateX(-50%);
border-width: 10px;
border-style: solid;
border-color: transparent transparent #474754 transparent;
}
.pagination-list-item{
  font-size: 18px;
  cursor: pointer;
  padding:0px 16px;
}
.pagination-list-item:hover{
color: #0ABAB5;
background: #252C32;
}
.pagination-list-item-active{
  color: #0ABAB5;
}
.arrow-down{
  margin-left:10px;
}
</style>
