export const globalConfig = {
    "backend":{
        url:"https://gateway.metabitglobal.com:18823/api",
        multiUrl:"https://gateway.metabitglobal.com:17763/api",
    },
    "metabit":{
        "domainId": "1",
        "networkId": 4321,
        "name": "Metabit",
        "decimals": 18,
        "rpcUrl": "https://mainnet.rpc.metabitglobal.com",
        "nativeTokenSymbol": "BMTC",
        "blockExplorer": "https://metabitglobal.io",
    },
    "address": {
        "nodeStaking": "0x1Ac1ed73c156196E495883f83075261eeeeCB72C",
    }

}
