import request from '@/utils/request'




export function getMyDelegates(data){
    return request({
        isNode: true,
        url: '/stat/getMyDelegates',
        method: 'get',
        data:data
    })
}

export function getDepositHistory(data){
    return request({
        multiSign:true,
        url: '/deposit/getDepositHistory',
        method: 'post',
        data:data
    })
}








export function getReferral(data){
    return request({
        url: '/user/getReferral',
        method: 'post',
        data:data
    })
}


// 用户验证信息
export function getAxiosVerifyMessage(address){
    return request({
        url: '/user/getVerifyMessage/'+address,
        method: 'get',
        isNeedToken:true,
    })
}
// 获取我的访问token
export function getAxiosAccessToken(data){
    return request({
        url: '/user/getAccessToken',
        method: 'post',
        isNeedToken:true,
        data:data
    })
}

// 刷新token操作
export function getAxiosRefreshAccessToken(data){
    return request({
        url: '/user/renewAccessToken?User-Account='+data.address+'&Access-Token='+data.token,
        method: 'get',
        isNeedToken:true,

    })
}



export function getUser(address){
    return request({
        url: '/user/get/'+address,
        method: 'get',
        multiSign:true
    })
}
export function register(data){
    return request({
        url: '/user/register',
        method: 'post',
        isNeedToken:true,
        data:data
    })
}

export function getSysParams(){
    return request({
        url: '/system/getSysParams',
        method: 'get',
        isNeedToken:true,
    })
}
export function join(data){
    return request({
        url: '/user/join',
        method: 'post',
        isNeedToken:true,
        data:data
    })
}
