<template>
    <div class="bottom">
        <div class="home-optimization">
            <div class="home-optimization-top">
                <div>This hardware configuration not only provides strong computing and verification capabilities </div>
                <div>but also achieves efficient network management and optimization.</div>
            </div>
            <div class="home-optimization-line"></div>
            <div class="home-optimization-bottom">
               <div class="ai-agent">AI Agent</div>
               <div>The essence of the AI agent is the performance DePin node, which is a performance asset </div>
               <div>based on NFT technology, used to enhance the computing power of the MetaLink network.</div>
            </div>
        </div>
        <div class="footer">
            <div class="bottom-left">
                <div class="bottom-left-item">
                    <img src="@/assets/home/tw.png" alt="" class="tw-img"/>
                    MetaLink_DePIN
                </div>
                <div class="bottom-left-item">
                    <img src="@/assets/home/tg.png" alt="" class="tw-img" />
                    MetaLink_DePIN
                </div>
        </div>
        <div class="bottom-right">
            Copyright © Metalink network 2024 – All rights reserved.
        </div>
        </div>
     
    </div>
</template>

<script>
export default {
    name: 'Bottom',
}
</script>

<style lang='less' scoped>
.bottom{
    // display: flex;
    // justify-content: space-between;
    // border-top:1px solid #313131;
    // padding:60px 134px;
    // color:#fff;
    background: url('@/assets/bottom/footer.png') no-repeat;
    background-size:100% 100%;
}
.footer{
    display: flex;
    justify-content: space-between;
    padding:60px 134px;
    color:#fff;
}
.bottom-left{
    display: flex;

}
.bottom-left-item{
    margin-right:16px;
    display: flex;
    align-items: center;
}
.tw-img{
    width:18px;
    height: 18px;;
    margin-right:5px;
}
.home-optimization-bottom{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top:44px;
    color:#FFF;
    text-align: center;

    /* R 20 */
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 180% */
}
.home-optimization-top{
    color:  #FFF;
text-align: center;

/* R 20 */
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 36px; /* 180% */
}
.home-optimization-line{
    width: 100%;
    height: 1px;
    margin-top:44px;
    background: linear-gradient(269deg, #00443D -7.57%, #FFF 27.09%, #00AA98 60.42%, #00443D 96.93%);
}
.home-optimization{
    width: calc(100% - 120px);
    margin:0px auto;
    padding-top:120px;
}
.ai-agent{
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    padding-bottom:24px;
    background: linear-gradient(271deg, #00AA98 24.64%, #FFF 57.99%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@media screen and (max-width: (768px - 1px)) {
    .footer{
        flex-direction: column;
        padding:24px;
        align-items:center;
    }
    .home-optimization-top{
        position: relative;
        z-index:33
    }
    .bottom-right{
        color:  #FFF;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 240% */
    }
    .bottom-left-item{
        color:  #FFF;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 240% */
    }
    .home-optimization-bottom{
        font-size: 12px;;
        line-height: normal;
    }
}
</style>
