/*
 * @Author: 1 1
 * @Date: 2023-02-07 16:12:00
 * @LastEditors: 1 1
 * @LastEditTime: 2023-03-25 23:30:06
 * @FilePath: /node_staking/src/store/modules/app.js
* @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Cookies from 'js-cookie'
import {getLanguage} from "@/lang";
// import { getLanguage } from '@/lang/index'
import {getAxiosVerifyMessage,getAxiosAccessToken,getAxiosRefreshAccessToken} from "@/api";
import Vue from 'vue';
import CryptoJS from 'crypto-js'
import {
    getAccount,
} from "@/utils/ether";
const vm = new Vue()
let  overdueTime = process.env.VUE_APP_TOKEN_OVERDUETIME
let curmessage
let userParams
let messageData
const state = {
    device: 'desktop',
    openNav: false,
    account:null,
    language: getLanguage(),
    connectAddress:localStorage.getItem('connectAddress')||'',
    // language: getLanguage(),
    dynamicOverView:null,
    userInfo: null,
    sysParam: null,
    maskVisible:false,
    accountInformation:{
        verifyMessage:'',
        signature:'',
        userAddress:''
    },
    accessToken:'',
    isSign:false,
    isStateLoadingSing:false,
    isTransitionLoading:'',
};
const mutations = {

    TOGGLE_DEVICE: (state, device) => {
        state.device = device
    },
    TOGGLE_NAV: (state, value) => {
        state.openNav = value
    },
    SET_LANGUAGE: (state, language) => {
        state.language = language
        Cookies.set('language', language)
    },
    SET_CONNECT_ADDRESS:(state, address)=> {
        state.connectAddress = address
    },
    SET_DYNAMIC_OVERVIEW:(state, overview)=>{
        state.dynamicOverView = overview
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_ACCOUNT: (state, account) => {
        state.account = account
    },
    SET_SYS_PARAM: (state, sysParam) => {
        state.sysParam = sysParam
    },
    SET_MASK_VISIBLE: (state, maskVisible) => {
        //console.log(maskVisible)
        state.maskVisible = maskVisible
    },
    SET_ACCOUNT_INFORMATION: (state, accountInfo) => {
        state.accountInformation = accountInfo
    },
    SET_ACCESS_TOKEN: (state, accessToken) => {
        state.accessToken = accessToken
    },
    SET_SIGN: (state, sign) => {
        state.isSign = sign
    },
    SET_LOADING_SIGN: (state, loadingSign) => {
        state.isStateLoadingSing = loadingSign
    },
    SET_TRANSITION_LOADING: (state, transitionLoading) => {
        state.isTransitionLoading = transitionLoading
    }
};
const  actions = {
    toggleDevice({commit}, device) {
        commit('TOGGLE_DEVICE', device)
    },
    setMaskVisible({commit}, maskVisible) {
        ////console.log("SET_USERINFO")
        commit('SET_MASK_VISIBLE', maskVisible)
    },
    setUserInfo({commit}, userInfo) {
        ////console.log("SET_USERINFO")
        commit('SET_USERINFO', userInfo)
    },
    setSysParam({commit}, sysParam) {
        ////console.log("SET_USERINFO")
        commit('SET_SYS_PARAM', sysParam)
    },
    setAccount({commit}, account) {
        window.localStorage.setItem('account', account)
        commit('SET_ACCOUNT', account)
    },
    setLanguage({ commit }, language) {
        commit('SET_LANGUAGE', language)
    },
    toggleNav({commit}, value) {
        return  new Promise((resolve,reject)=>{
            commit('TOGGLE_NAV', value)
            resolve()
        })
    },
    setConnectAddress({commit}, address) {
        commit('SET_CONNECT_ADDRESS', address)
    },
    setDynamicOverView({commit}, overview){
        commit('SET_DYNAMIC_OVERVIEW', overview)
    },
    setAccountInformation({commit}, data){
        commit('SET_ACCOUNT_INFORMATION', data)
    },
    setAccessToken({commit}, accessToken){
        commit('SET_ACCESS_TOKEN', accessToken)
    },
    setSign({commit}, sign){
        commit('SET_SIGN', sign)
    },
    setLoadingSign({commit}, sign){
        commit('SET_LOADING_SIGN', sign)
    },
    setTransitionLoading({commit}, transition){
        commit('SET_TRANSITION_LOADING', transition)
    },
    decrypt(state,{str, secret}) {

        // if(!secret) return
        const cryptoKey = CryptoJS.enc.Utf8.parse(secret);
        const cryptoOption = {
          iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        };
        const decryptedStr = CryptoJS.AES.decrypt(str, cryptoKey, cryptoOption).toString(
          CryptoJS.enc.Utf8,
        );
        return decryptedStr;

      },
    encrypt(state,{str, secret}){
        //console.log(str, secret)
        const cryptoKey = CryptoJS.enc.Utf8.parse(secret);
        const cryptoOption = {
            iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        };
        const encryptedStr = CryptoJS.AES.encrypt(str, cryptoKey, cryptoOption).toString();
        //console.log(encryptedStr,'encryptedStr')
        return encryptedStr;
    },

    async refreshToken({commit,dispatch},accessToken){
        const that = this
         return new Promise(async (resolve, reject) => {
             let account = await getAccount()
            //console.log(account,'account==.')
            // dispatch('app/setMaskVisible',true)
            // vm.$showLoading()
            let storeAccountToken = JSON.parse(localStorage.getItem('storeAccountToken'))
            //console.log(storeAccountToken,'messageData==>storeAccountToken')
            const curTokenTimestamp = vm.$dayjs.utc().local().valueOf()
            const newUtcTimestamp = storeAccountToken && storeAccountToken[account] && Number(storeAccountToken[account].startTime) + parseInt(overdueTime/2)*60*1000
            //  获取当前的账户信息
            if(!storeAccountToken || !storeAccountToken[account]){
                // if(!messageData || curTokenTimestamp>newUtcTimestamp){
                    messageData = await getAxiosVerifyMessage(account)
                // }
                try{
                    localStorage.setItem('isLoadingSing',true)
                    curmessage = await window.ethereum.request({
                        method: 'personal_sign',
                        params: [messageData.data,  account], // 要签名的消息和账户地址
                    })
                }catch(err){
                    //console.log(err,'err',err.code)
                    if(err.code==4001){
                        // this.$msg.error("取消签名！")
                        dispatch('setMaskVisible',true)
                        dispatch('setSign',true)
                        if(storeAccountToken && storeAccountToken[account]){
                            delete storeAccountToken[account]
                            localStorage.setItem('storeAccountToken',JSON.stringify(storeAccountToken))
                        }

                    }
                }
                if(!curmessage) return;

                 userParams = {
                    userAddress:account,
                    verifyMessage:messageData.data,
                    signature:curmessage,
                }
                const  dataToken = await getAxiosAccessToken(userParams)
                if(!dataToken || !dataToken.data) { return false }
                localStorage.setItem('isLoadingSing',false)
                if(storeAccountToken && !storeAccountToken[account]){
                    storeAccountToken[account]={address:account,startTime:vm.$dayjs.utc().local().valueOf(),accessToken:dataToken.data.accessToken,verifyMessage:messageData.data,signature:curmessage}
                }else{
                    storeAccountToken  = {
                        [account]:{address:account,startTime:vm.$dayjs.utc().local().valueOf(),accessToken:dataToken.data.accessToken,verifyMessage:messageData.data,signature:curmessage}
                    }

                 }
                dispatch('setMaskVisible',false)
                dispatch('setSign',false)
                vm.$hideLoading()
                localStorage.setItem('isLogin',true)
                localStorage.setItem('storeAccountToken',JSON.stringify(storeAccountToken))
                setTimeout(()=>{
                    location.reload()
                },0)
                return resolve(dataToken)
            }else{

                //console.log('accessToken===>',storeAccountToken)
                // const accessExpriceToken = await dispatch('decrypt',{str:localStorage.getItem('accessToken'),secret: localStorage.getItem('verifyMessage')})
                const accessExpriceToken = storeAccountToken[account].accessToken
                //console.log(accessExpriceToken,'accessExpriceToken')
                userParams = {
                    address:account,
                    token:accessExpriceToken
                }
                const curTokenTimestamp = vm.$dayjs.utc().local().valueOf()

                const newUtcTimestamp = storeAccountToken && storeAccountToken[account]&& Number(storeAccountToken[account].startTime) + parseInt(+overdueTime/2)*60*1000

                 const  overdueTimeUtcTimestamp = storeAccountToken && storeAccountToken[account]&& Number(storeAccountToken[account].startTime) + parseInt(+overdueTime)*60*1000
                if(curTokenTimestamp>overdueTimeUtcTimestamp){

                    delete storeAccountToken[account]
                    localStorage.setItem('storeAccountToken',JSON.stringify(storeAccountToken))
                    dispatch('refreshToken')
                    return false
                }
                //console.log(userParams,'userParams==>')
                const  curDataToken = await getAxiosRefreshAccessToken(userParams)
                //console.log(curDataToken,'userParams==>')
                if(!curDataToken || !curDataToken.data) return;
                let curMapStore = {
                   startTime:vm.$dayjs.utc().local().valueOf(),accessToken:curDataToken.data.accessToken
                }
                storeAccountToken[account] = {...storeAccountToken[account],...curMapStore}
                vm.$hideLoading()
                dispatch('setMaskVisible',false)
                 dispatch('setSign',false)
                localStorage.setItem('storeAccountToken',JSON.stringify(storeAccountToken))
                return resolve(curDataToken)
            }
        })
    }
}

 export default {
    namespaced: true,
    state,
    mutations,
    actions
  }

