<template>
    <div   :class="type=='confirm'?'dialogModal':'msgModal'" v-if="visible" @click.stop="copy" >

            <div class="title"  v-if="type == 'confirm'">{{ title}}</div>
            <!-- 图标区 -->
            <div class="iconDiv">
                <img v-if="type=='success'" :src="require('@/assets/comp/suc-icon.png')" />
                <img v-if="type=='warning'"  :src="require('@/assets/comp/warn.png')" />
                <img v-if="type=='error'"  :src="require('@/assets/comp/fail-icon.png')" />
            </div>

            <!-- 消息 -->
            <div class="message">
                {{ message }}
                <div  class="layerButtonContainer" v-if="configOptions.show && type=='success'">
                    <div class="view-text">
                        <a :href="configOptions.link" target="_blank">
                          {{ configOptions.text }}
                        </a>
                    </div>
                </div>
            </div>

            <div class="header-close">
            <img src="@/assets/home/close-white.png" class="close-img" @click="closeFn">
           </div>
        <!-- confirm区-->


        <!-- 按钮 -->
            <div class="layerButtonContainer" v-if="type == 'confirm'">
                <button class="layerButton" @click="clickNo" v-if="false">否</button>
                <button class="layerButton" @click="clickYes">{{ yes }}</button>
            </div>


    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    data() {
        return {
            visible:false,
            yes:"OK",
            message:'',
            mask:false,
            type:'message',     //loading, success, error, warning, question, message
            callBack: null,         //询问框点击是时的回调函数
            configOptions:{}
        }
    },
    methods: {
        ...mapActions("app",["setMaskVisible"]),
        copy() {
            this.$copyText(this.message)
        },
        clickYes(){
            if(typeof this.callBack === 'function'){
                this.callBack();
            }
            this.visible=false;
            this.setMaskVisible(false)
        },
        clickNo(){
            this.visible=false;
            this.setMaskVisible(false)
        },
        closeFn(){
            this.visible=false;
            // this.setMaskVisible(false)
        },
        setMask(){
            // this.setMaskVisible(true)
        }

    },
    computed: {

    },
    mounted() {
    },
}

</script>
<style lang="less" scoped>
.view-text{
    display: flex;
    align-items: center;
    gap: 10px;
    color:  #F6F8F9;
    text-align: center;
    background: #3C464E;
    border-radius: 6px;
    padding:5px 8px;
    width: 133px;
    cursor: pointer;
    /* UI / UI Text 14 Semi Bold */
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.084px;
    margin-top:12px;
    white-space: nowrap;
    a{
        color:#F6F8F9;
        white-space: nowrap;
    }
}
.msgModal{
    position: fixed;
    top: 8vh;
    right: 5vh;

    display: flex;
    width: 320px;
    padding: 16px 16px 24px 16px;
    align-items: flex-start;
    z-index: 999;
    border-radius: 6px;
    border: 1px solid  #3C464E;
    background:  #303940;
    display: flex;
    .iconDiv{
        width: 24px;
        height: 24px;
        min-width: 24px;
    }
    .message{
        font-family: BaiJamjuree-Regular, BaiJamjuree;
        color: #fff;
        text-shadow: 0px 2px 14px rgba(0,0,0,0.5);
        margin-bottom: 10px;
        margin-left:8px;
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-right: 16px;
    }
    .header-close{
        position: absolute;
        right:16px;
        top:16px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
.dialogModal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    width: 315px;
    height: 277px;
    background: #2B2E3D;
    border-radius: 10px;

    border: 1px solid rgba(182,248,82,0.5);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around ;
    .title{
        font-size: 22px;
        font-family: BaiJamjuree-SemiBold, BaiJamjuree;
        font-weight: 600;
        color: #51FFA8;
        line-height: 28px;
    }
    .iconDiv{
    }
    .message{
    }
    .layerButtonContainer{
        display: flex;
        gap: 20px;
        .layerButton{
            width: 118px;
            height: 45px;
            background: #51FFA8;
            border-radius: 6px;
            border: 0;
            font-size: 17px;
            font-family: BaiJamjuree-SemiBold, BaiJamjuree;
            font-weight: 600;
            color: #191B25;
            line-height: 21px;

        }

    }

}
@media screen and (max-width: (768px - 1px)) {
    .msgModal{
        width: calc(100% - 64px);
        right: 16px;
        // padding:16px;
    }
    .msgModal .message{
        width:216px;
    }
}

</style>
