
import CryptoJS from 'crypto-js'
import { Decimal } from 'decimal.js'
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // 导入UTC插件
import advancedFormat from 'dayjs/plugin/advancedFormat';
export function comingSoon(address){
  this.$msg.warning(this.$t("global.comingSoon"),3000,true)

  // this.$message.success("Coming soon！")

}
export function goBuy() {
    this.$router.push({
      name: 'cardPurchase'
    })
}
export function goBack() {
  this.$router.back()
}


export function goPersonal() {
    this.$router.push({
      name: 'Personal'
    })
}
export function goLpMining() {
  this.$router.push({
    name: 'lpMining'
  })
}

export function goMyCommunity() {
  this.$router.push({
    name: 'MyCommunity'
  })
}

export function goExtractRecords() {
    this.$router.push({
        name: 'ExtractRecords'
    })
}
export function goLpRewardsHistory() {
    this.$router.push({
        name: 'LpRewardsHistory'
    })
}
export function txHashFn(hashValue){
    return hashValue.slice(0,4)+'****'+hashValue.slice(-4)
}
export function addressFilter(address){
    return address?address.slice(0,6)+'...'+address.slice(-5):''
}
/*** 公共跳转路径 ***/
export function goCommonPath(pathName,option){
  console.log(option,'option===>')
  if(option){
    this.$router.push({
      name:pathName,
      query:{ from:option.from}
    })
  }else{
    this.$router.push({
      name:pathName,
    })
  }

}

export function sleep(d){
  for(var t = Date.now();Date.now() - t <= d;);
}


/*** 倒计时 ***/
export function formatDuration(duration) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;
  // 使用 padStart 函数来确保分钟和秒钟都是两位数的格式
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

/**
 * 加密
 * @param {string} str 需要加密的数据
 * @param {string} secret 密钥
 * @returns
 */
export function encrypt(str, secret) {
  try{
  const cryptoKey = CryptoJS.enc.Utf8.parse(secret);
  const cryptoOption = {
    iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  };
  const encryptedStr = CryptoJS.AES.encrypt(str, cryptoKey, cryptoOption).toString();
  console.log(encryptedStr,'encryptedStr')
  return encryptedStr;
}catch(err){
  console.log(err)
}
}

/**
 * 解密
 * @param {string} str 需要解密的数据
 * @param {string} secret 密钥
 * @returns
 */
export function decrypt(str, secret) {
  const cryptoKey = CryptoJS.enc.Utf8.parse(secret);
  const cryptoOption = {
    iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  };
  const decryptedStr = CryptoJS.AES.decrypt(str, cryptoKey, cryptoOption).toString(
    CryptoJS.enc.Utf8,
  );
  return decryptedStr;
}


export function getRandomNum(min,max){
  //可选择最小值和最大值，生成一个区间中的随机数
    var range = max - min;
    var rand = Math.random();
    return (min + Math.round(rand * range));
  }


export function debounce (fn, delay = 1000) {
  let timer = null
  return () => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(fn, delay)
  }
}



export function subReduce(num1, num2) {
  // 创建 Decimal 对象
  const decimalNum1 = new Decimal(num1);
  const decimalNum2 = new Decimal(num2);

  // 进行相减并保留 6 位小数
  const result = decimalNum1.minus(decimalNum2).toDecimalPlaces(6);

  // 返回结果
  return result.toString()
}
export function utcToLocal(utcTime, format) {
    return dayjs.utc(utcTime).local().format(format)
}


export function handleErr(context,err){
    let errorMessage = '';
    console.log(err)
    switch (err.code) {
        case 4001:
            errorMessage = context.$t("web3.ACTION_REJECTED");
            break;
        case "INVALID_ARGUMENT":
            // 参数错误
            errorMessage = context.$t("web3.INVALID_ARGUMENT")+",message:  "+ err.message;
            break;
        case "ACTION_REJECTED":
            errorMessage = context.$t("web3.ACTION_REJECTED");
            break;
        case "TRANSACTION_REPLACED":
            errorMessage = context.$t("web3.ACTION_REJECTED");
            break;
        case -32603:
            errorMessage = context.$t("web3.err32603")+",message:  "+ err.message;
            break;
        default:
            errorMessage = context.$t('web3.err32603')+",message:  "+ err.message;
    }
    context.$msg.error(errorMessage);

}
