<template>
    <div id="app" ref="app" v-resize="resize">
        <!-- <div class="about-header-back" :class="isHomeRoute?'about-header-excute':''"></div> -->
        <div class="home-pc-nav" v-if="path">
            <img src="@/assets/matebit-logo.png" alt="" class="matebit-logo" />
            <div class="home-nav-list">
                <div class="home-nav-list-item " :class=" isActive('/overview')?'home-nav-list-item-active':''"  @click="()=>routerHandel('overview')">
                    <img src="@/assets/router/property-menu-icon.png" alt=""  class="menu-img">
                    资产管理
                </div>
                <div class="home-nav-list-item" :class="isActive('/transfer')?'home-nav-list-item-active':''" @click="()=>routerHandel('transfer')">
                    <img src="@/assets/router/bill-menu-icon.png" alt="" class="menu-img">
                    交易记录
                </div>
            </div>

        </div>
       <div class="app-main" >
        <div class="app-main-header" v-if="path">
          <div>{{ this.$route.meta.title }}</div>
          <div class="app-main-header-right relative">
            <div class="flex items-center cursor-pointer"  @click="outOpenHandle">
               <div class="text-[16px] font-semibold">{{ addressFilter(account) }}</div>
               <img src="@/assets/comp/arrow-down.png" alt=""  class="w-[20px] h-[10px] ml-[5px]"/>
            </div>
            <div class="chain-text">METABIT Mainnet</div>
            <div class="absolute top-[50px] w-[180px] custom-box-shadow rounded-[6px] bg-white p-[16px] z-[555]" v-if="isDownOut">
               <div class="cursor-pointer" @click="outHandle"> login out</div>
            </div>
          </div>
        </div>
        <router-view class="view"/>
       </div>

        <div class="registerModal invite-registerModal" v-if="dialogVisible" @click.stop="">
           <img src="@/assets/home/close-white.png" class="close-img" alt="" @click.stop="closeJoinDialog">
            <div class="title">Invite Friends</div>
             <div class="subtitle">Copy the link below and share it to invite your friends to join Uno </div>

             <div class="invite-main">
                 <div class="invite-link">Invite Link</div>
                 <div class="user-link">
                    <textarea :placeholder="$t('home.referalPlaceHolder')"  v-model="referralAddress"
                      class="referral-textarea" disabled/>

                </div>
             </div>
            <div class="layerButtonContainer layerButtonContainer-link">
                <button class="layerButton copy-link"
                    v-clipboard:copy="referralAddress"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                 >Copy Link</button>
            </div>
            <div class="your-Inviter" v-if="inviterAddress">Your Inviter</div>
            <div class="invite-content" v-if="inviterAddress">
                <div>{{ formatAddress(inviterAddress,10) }}</div>
                <div>{{  inviterTime  }}</div>
            </div>
            <div class="your-invitee">Your Invitee</div>
            <div class="extract-wrap">
                <vue-good-table
                    ref="myTable"
                    :key="tableKey"
                    :columns="columns"
                    :rows="inviteeList"
                    :sort-options="sortOptions"
                    :pagination-options="paginationOptions"
                    @on-sort-change="handleSort"

                >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field =='userAddress'">
                        <span class="custom-amount">{{ props.row.userAddress  }}</span>
                    </span>

                    <span class="custom-col-content" v-if="props.column.field =='createTime'">
                        <span >{{ utcToLocal(props.row.createTime,'YYYY-M-D HH:mm:ss')   }}</span>
                    </span>
                </template>

                <template slot="emptystate" >
                    <span v-if="!showNoData && !totalDataNumber"></span>
                    <div class="custom-empty-message" v-if="!totalDataNumber && showNoData">
                        <img src="@/assets/comp/no-data.png" class="no-date-img" />
                        {{ $t("global.noRecords") }}
                    </div>
                </template>
            <!-- 自定义分页插槽 -->
                <template v-slot:pagination-bottom="slotProps" >
                    <v-pagination
                        :totalPage='totalDataNumber'
                        :current='pageParams.page'
                        :pagesize="paginationOptions.perPage"
                        @change-page='getHistory'
                        v-if="inviteeList.length>0"
                    />
                    <span v-else></span>
                </template>

            </vue-good-table>
            </div>
        </div>
        <!-- 邀请码 -->
        <div class="registerModal" v-if="isVisible" @click.stop="">
           <img src="@/assets/home/close-white.png" class="close-img" alt="" @click.stop="closeJoinDialog">
            <div class="title subtitle-register">{{ $t("home.ReferralCode") }}</div>

             <div class="invite-main">
                 <div class="user-link">
                    <textarea :placeholder="$t('home.referalPlaceHolder')"  v-model="registerReferrallAddress"
                      class="referral-textarea"/>

                </div>
             </div>
            <div class="layerButtonContainer">
                <button class="layerButton"
                  @click="bindReferral"
                 >Submit</button>
            </div>
        </div>
        <div class="mask" v-show="maskVisible"></div>

       <div class="registerModal registerModal-sign"  v-if="isSign">
        <div class="title">{{ $t('global.signTitle') }}</div>
          <div class="content-sign">{{ $t('global.signContent') }}</div>
          <div class="layerButtonContainer">
                <button class="layerButton" @click="signatureHandle">{{ $t('global.signBtn') }}</button>
            </div>
       </div>


    </div>
</template>
<script>
import {mapGetters, mapState, mapActions} from "vuex";
import BottomVue from './components/Bottom.vue';
import {formatAddress, getAccount,  RetainDecimal, smartChangeNetwork} from "@/utils/ether";
import { getUser, register, join,  getReferral} from "@/api";
import {utils} from "web3";

import Vue from "vue";
import {addressFilter, utcToLocal} from "@/utils/common";



export default {
    components: {BottomVue},
    watch: {
        async $route(route) {
            const {path, name} = route;
            console.log(name,'name==>')
            this.path = name=='login'?'':name
            this.$nextTick(() => {
                // const dom = document.querySelector('#home-link')
                // if (dom) {
                //     dom.className = name == 'about' ? "home-link router-link-active" : "home-link text-gray"
                // }
                // this.toggleNav(false)
            })
        },
    },

    data() {
        const that = this;
        return {
            activeMenuIndex:'',
            isVisible:false,
            aboutType:'',
            registerReferrallAddress:'',
            referralAddress:'',
            langMenuShow: false,
            isMobile: false,
            dialogVisible:false,
            referralCode:null,
            arriveText:this.$t('home.arriveOver'),
            notice:this.$t('global.noticeText'),
            isClaim:false,
            isClaimDialog:false,
            isLogin:false,
            isLoginOut:false,
            inviterAddress:null,
            inviterTime:null,
            isHomeRoute:false,
            aboutOption:[
                { text: 'Whitepaper', value: 'Whitepaper',img:require('@/assets/router/about-book.png'),path:'whitepaper' },
                { text: 'Innovation', value: 'Innovation' ,img:require('@/assets/router/about-search.png'),path:'innovation' },
                { text: 'User Community', value: 'User Community',img:require('@/assets/router/about-users.png'),path:'about' },
                { text: 'Product Roadmap', value: 'Product Roadmap',img:require('@/assets/router/roadmap.png'),path:'roadmap' }
            ],
            aboutIdoOption:[
               { text: 'UNO Token IDO', value: 'UNO Token IDO',img:require('@/assets/router/about-token.png'),path:'ido' },
               { text: 'UNO Node IDO', value: 'UNO Node IDO' ,img:require('@/assets/router/about-node.png'),path:'nodeido' },
               { text: 'Handbook', value: 'Handbook' ,img:require('@/assets/router/handbook.png'),path:'Handbook' },
            ],
            aboutBuildOption:[
               { text: 'Dev home', value: 'Dev home',img:require('@/assets/router/build-home.png'),path:'build' },
               { text: 'Node', value: 'Node' ,img:require('@/assets/router/build-cube.png'),path:'build' },
               { text: 'Interchain', value: 'Interchain' ,img:require('@/assets/router/build-link.png'),path:'build' },
            ],
            isAboutDown:false,
            isIdoDown:false,
            isBuildDown:false,
            isAddressDown:false,
            sortOptions: { // 表格
             enabled: true, // 禁用排序
             initialSortBy: {}
            },
            tableKey: 0,
            columns:[
                {
                label: 'Address',
                'no-sort': false,
                field: 'userAddress'
                },
                {
                label: 'Binding Date',
                field: 'createTime',
                'no-sort': false,

                },

            ],
            sortType:{
                userAddress:{asc:1,desc:2},
                createTime:{asc:3,desc:4},
            },
            showNoData:true,
            totalDataNumber:0,
            sideTotalDataNumber:2,
            inviteeList:[

            ],
            dataList:[],
            currentPage:1,
            paginationOptions: {
                enabled: true, // 启用分页
                perPage: 10,
                currentPage:1,
                setCurrentPage:1 //当前表格页码 // 每页显示的行数
            },
            pageParams:{
                "page": 1,
                "pageSize": 10,
                "sortType":2
            },

            isDownOut:false,
            path:''
        }
    },
    directives: {  // 使用局部注册指令的方式
        resize: { // 指令的名称
            bind(el, binding) { // el为绑定的元素，binding为绑定给指令的对象
            let width = '', height = '';
            function
             isReize() {
                const style = document.defaultView.getComputedStyle(el);
                if (width !== style.width || height !== style.height) {
                binding.value();  // 关键
                }
                width = style.width;
                height = style.height;
            }
            el.__vueSetInterval__ = setInterval(isReize, 300);
            },
            unbind(el) {
            clearInterval(el.__vueSetInterval__);
            }
        }
    },
    computed: {
        ...mapGetters(['openNav', 'account', 'userInfo', 'language', 'maskVisible', 'sysParam','accountInformation','isSign']),
        filterRoute:function(){
           console.log(this)
        }
        //  isMobile() {
        //       return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        //   }
    },
    async mounted() {
        // this.$msg.success('Your transaction is completed, please check your transaction detail on block explorer.', 5000, true)


        console.log(localStorage.getItem('isLogin'),'isLogin')
        this.isLogin =  localStorage.getItem('isLogin')
        this.$router.afterEach(this.checkRoute);
        const {name} = this.$route

        this.path = name=='login'?'':name
        if (name == 'about' ) {
            const dom = document.querySelector('#home-link')
            console.log(dom,'dom===>')
            if (dom) {
                dom.className =  name == 'about' ? "home-link router-link-active" : "home-link text-gray"
            }
        }

        const isInitMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        if (isInitMobile) {
            this.isMobile = true
        }
        let that = this

        if (this.sysParam == null) {
            // let {data} = await getSysParams()
            // this.setSysParam(data)
        }

        if (window.ethereum) {
            try{
                   await this.web3Init()


            }catch(err){
                console.log(err,'err===>')
            }
            // await this.signIn()

        // } else {
        }

        setTimeout(function () {
            if (!window.ethereum) {
                that.setMaskVisible(true)
                that.$msg.error(that.$t("global.useDappExplorer"), -1, true)
            }
        }, 5000); // 3 seconds

       const refList = [
        {
          closeKey:'isAboutDown',
          ref: this.$refs.aboutRef
        },
        {
          closeKey:'isShowDropDown',
          ref: this.$refs.idoRef
        },
        {
          closeKey:'isClaim',
          ref: this.$refs.infiLoginRef
        }]

        document.addEventListener("click", e => {
            if(!this.isMobile){
                if (this.$refs.aboutRef) {
                let self = this.$refs.aboutRef.contains(e.target);
                if (!self) {
                    this.isAboutDown = false;
                }
            }
            if (this.$refs.idoRef) {
                let self = this.$refs.idoRef.contains(e.target);
                if (!self) {
                    this.isIdoDown = false;
                }
            }
            }


            if (this.$refs.infiRef) {
                let self = this.$refs.infiRef.contains(e.target);
                if (!self) {
                    this.isClaim = false;
                }
            }
            if(this.$refs.infiLoginRef){
                let self = this.$refs.infiLoginRef.contains(e.target);
                if (!self) {
                    this.isLoginOut = false;
                }
            }
        })


    },
    beforeDestroy() {
        this.$router.afterEach(null);
    },
    methods: {
        addressFilter,
        RetainDecimal,
        utcToLocal,
        formatAddress,
        ...mapActions('app', ['toggleNav', 'setLanguage', 'setAccount', 'setUserInfo', 'setMaskVisible', 'setSysParam','setAccountInformation','refreshToken','setSign']),
        routerHandel(path){
            this.activeMenuIndex = path
            this.$router.push({name:path})
        },
        isActive(path){
            return this.$route.path === path;

        },
        checkRoute() {
            const routeList = ['/ido','/economics','/nodeido','/innovation','/roadmap']
            this.isHomeRoute = routeList.includes(this.$route.path);
        },
        comingHandle(){
            this.$msg.warning('Coming Soon!', 3000,true)
            return false;
        },
        routerHandle(routerType){
         console.log(routerType,'routerType', this[routerType])
         if(routerType=='isBuildDown'){
            this.$msg.warning('Coming Soon!', 3000,true)
            return false;
         }
         this[routerType] = !this[routerType]
        //  if(!this.isMobile){
            const routerList = ['isAboutDown','isIdoDown','isBuildDown']
            for(let i = 0;i<routerList.length;i++){
                if(routerList[i]!==routerType){
                   this[routerList[i]] = false
                }
            }
        //  }
        },
        addressHandle(){
            this.isAddressDown = !this.isAddressDown
        },
        changeDropDown(type,routerType,path){
            console.log('routerType',routerType,path)
            switch(routerType){
                case 'isAboutDown':
                if(path=='whitepaper'){
                    window.open('https://uno-networks-organization.gitbook.io/uno-whitepaper-v1/')
                   return;
                }
                this.aboutType = type
                break;
                case 'isIdoDown':
                if(path=='Handbook'){
                    window.open('/handbook.pdf')
                   return;
                }
                this.aboutType = type
                break;
                case 'isBuildDown':
                this.aboutType = type
                break;
            }
            const routerList = ['isAboutDown','isIdoDown','isBuildDown']

             if(!this.isMobile){

                for(let i = 0;i<routerList.length;i++){

                    this[routerList[i]] = false
                }
             }else{

                for(let i = 0;i<routerList.length;i++){
                console.log(routerType,'uisdus ')
                    if(routerList[i]!==routerType){
                        this[routerList[i]] = false
                    }

                }
             }
            // this.isAboutDown = false
            // this.isIdoDown = false
            // this.isBuildDown = false
            setTimeout(()=>{

                this.$router.push({name:path})
                this.toggleNav(!this.openNav)
            },100)
            // this.$router.push({name:path})


            // this[routerType] = false

        },
        handleSort(event) {
        // Retrieve the sort information from the event
        const { field, type } = event[0];
         this.pageParams.sortType = this.sortType[field][type]
         this.getHistory(this.pageParams.page)
        },
        async getHistory(current,options){
            let account = await getAccount()
            console.log(current,'current===>',options)
            let params = {...this.pageParams,...{address:account}}
            if(current){
                this.pageParams.page = current
                params = {...this.pageParams,...{page:current}}
                this.paginationOptions = {...this.paginationOptions,...{setCurrentPage:current},...{currentPage:current}}
            }
            if(options){
                if(options.perPage){
                    params = {...params, ...{pageSize:options.perPage}}
                    this.pageParams.pageSize = options.perPage
                }
                this.paginationOptions = {...this.paginationOptions,...options}
                this.tableKey++
            }
            let result1 = await getReferral(params)

            if(result1 != null){
                this.inviteeList= result1.data
                this.totalDataNumber = result1.count||0
            }
        },
        resize(){
            console.log('resize==>')
            const reg = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            const isInitMobile = reg.test(navigator.userAgent);
            console.log(isInitMobile,'isInitMobile==>')
            if (isInitMobile) {
                this.isMobile = true
            }else{
                this.isMobile = false;

            }

        },
        rightHandle(){
          this.isLoginOut = !this.isLoginOut
        },
        submitHandle(){

        },
        // 链接钱包
        async connectHandle(){
            // await this.web3Init()
            this.isLogin= true
            this.isConnectWallet =false
            this.isLoginOut = false
        },
        outOpenHandle(){
            this.isDownOut = true
        },
        //  退出登录
        async outHandle(){
             const account = await getAccount()
            localStorage.removeItem('account')
            localStorage.removeItem('isLogin')
            localStorage.removeItem('storeAccountToken')
            // const storeAccountToken = localStorage.getItem('storeAccountToken')
            // delete storeAccountToken[account]
            // localStorage.setItem('storeAccountToken',storeAccountToken)
            this.isLogin = false
            this.isDownOut = false
            this.$router.push({name:'login'})

        },
        async inviteHandle() {
            const account = await getAccount()

            this.referralAddress = '?referral='
            console.log(this.referralAddress)
            this.setMaskVisible(true)
            this.dialogVisible = true;
        },
        closeHandle(){
            this.setMaskVisible(false)
            this.isClaimDialog = false;
        },
        signatureHandle(){
            // this.setMaskVisible(false)
           this.setSign(false)
            this.refreshToken()
        },
        async web3Init() {

            //若不是dapp浏览器，弹出警告。block全屏
            await smartChangeNetwork()
            localStorage.setItem('isStoreToken',1)
            let that = this
            let account = await getAccount()
            this.setAccount(account)

            window.ethereum.on('accountsChanged', async function (accounts) {
                let account = await getAccount()
                that.setAccount(account)
                that.$msg.success(that.$t("global.changeAccount") + formatAddress(account))

                setTimeout(function () {
                    window.location.reload()

                }, 2000)
            });
            // window.ethereum.on('chainChanged', function (accounts) {
            //     debugger
            //     const vm = new Vue();
            //     window.location.reload()
            // });
            try{
                // let storeAccountToken =  JSON.parse(localStorage.getItem('storeAccountToken'))
                // console.log(storeAccountToken,'首页====》')
                // let isHasExistAccount = storeAccountToken && storeAccountToken[account]
                // if(!storeAccountToken){
                //      await this.refreshToken()
                // }else if(!isHasExistAccount){
                //     this.setSign(true)
                //     this.setMaskVisible(true)
                //     this.setSign(true)
                // }
            }catch(err){
                console.log(err,'err')
            }





        },
        changeHandle(lan) {
            this.$i18n.locale = lan
            // this.setLanguage(lan)
            this.langMenuShow = false
            window.location.reload()
        },

        closeJoinDialog(){
            this.dialogVisible=false
            this.setMaskVisible(false)
            this.isVisible = false
        },
        encrypt(str, secret){
            const cryptoKey = CryptoJS.enc.Utf8.parse(secret);
            const cryptoOption = {
                iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            };
            const encryptedStr = CryptoJS.AES.encrypt(str, cryptoKey, cryptoOption).toString();

            return encryptedStr;
        },
        async signIn() {
            try{
                let storeAccountToken =  JSON.parse(localStorage.getItem('storeAccountToken'))
                if(!storeAccountToken){
                    this.setMaskVisible(true)
                    this.setSign(true)
                }

                this.getHistory()
                let account = await getAccount()
            //根据地址查询用户信息，若用户未注册，发起注册操作
                let result = await getUser(account)

                 console.log(result,'result==>')

            if(result.code==200){
                let data = result.data
                this.setUserInfo(data)
                console.log(data,'data===>')
                //获取入参，判断是否有邀请链接，若有,注册时带入服务端
                let referral = this.$route.query.referral
                this.referralCode=referral || ""
                if (data == null ) {


                    await this.register()
                }else{
                    //用户已注册且未绑定邀请人，且目前已获得格式正确的邀请人地址，绑定
                    if ((data.inviterAddress==null || data.inviterAddress=="") && referral && utils.isAddress(referral)) {
                        let result = await this.join();
                        if(result.code==200){
                            let data = result.data
                            this.setUserInfo(data)
                        }
                    }
                }
                this.inviterAddress=this.userInfo.inviterAddress
                this.inviterTime= utcToLocal(this.userInfo.updateTime,'MMMM D, YYYY')
            }
          }catch(err){
            console.log(err,'err===>')
          }
        },
        onAccountCopy:function(e){
            this.$msg.success('Copy Success')
        },
         //复制成功的回调
        onCopy:function(e){
            this.$msg.success(this.$t("home.BindSucc"))
        },
        //复制失败的回调
        onError:function(e){
            this.$msg.error('复制失败!')
        },
        async bindReferral() {

            if(await this.register()){
                this.closeJoinDialog()
                this.$msg.success(this.$t("home.BindSucc"))
                setTimeout(function () {
                    window.location.reload()

                }, 2000)
            }

        },

        async join() {
            //若当前已有绑定关系，不允许再绑
            if(this.userInfo!=null && this.userInfo.inviterAddress!=null&& this.userInfo.inviterAddress!=""){
                this.$msg.error(this.$t("home.BindDuplicate"))
                return false
            }
            //获取输入的推荐码
            //推荐码非空判断
            if(this.referralCode==null || this.referralCode ==""){
                this.$msg.error(this.$t("home.referralCodeEmpty"))
                return false
            }
            //推荐码格式判断
            if(!utils.isAddress(this.referralCode)){
                this.$msg.error(this.$t("home.referralCodeFormat"))
                return false
            }
            //推荐码不能是自己
            let account = await getAccount()
            if(this.referralCode.toLocaleLowerCase() == account.toLocaleLowerCase()){
                this.$msg.error(this.$t("home.referSelf"))
                return false
            }

            let param = {
                userAddress: account,
                inviterAddress: this.referralCode
            }
            let result = await join(param)
            if (result.data != null) {
                this.setUserInfo(result.data)
            }
            return  true
        },


        async register() {
            let account = await getAccount()

            //若当前已有绑定关系，不允许再绑
            if(this.userInfo!=null && this.userInfo.inviterAddress!=null&& this.userInfo.inviterAddress!=""){
                this.$msg.error(this.$t("home.BindDuplicate"))
                return false
            }
            //获取输入的推荐码
            //推荐码非空判断
            // if(this.referralCode==null || this.referralCode ==""){
            //     this.$msg.error(this.$t("home.referralCodeEmpty"))
            //     return false
            // }
            //推荐码格式判断
            if(this.referralCode!=null && this.referralCode !=""){
                if(!utils.isAddress(this.referralCode)){
                    this.$msg.error(this.$t("home.referralCodeFormat"))
                    return false
                }
                //推荐码不能是自己
                if(this.referralCode.toLocaleLowerCase() == account.toLocaleLowerCase()){
                    this.$msg.error(this.$t("home.referSelf"))
                    return false
                }
            }

            let param = {
                userAddress: account,
                inviterAddress: this.referralCode
            }
            let result = await register(param)
            if (result.data != null) {
                this.setUserInfo(result.data)
            }
            return  true
        },
        toggleLangNav() {
            this.langMenuShow = !this.langMenuShow
        },
        toggleMobileNav() {
            const {name} = this.$route
            this.toggleNav(!this.openNav)
            this.$nextTick(() => {
                const dom = document.querySelector('#home-link1')
                dom.className = name == 'about' ? "home-link router-link-active" : "home-link text-gray"
            })
            const routerList = ['isAboutDown','isIdoDown','isBuildDown']
            for(let i = 0;i<routerList.length;i++){
                   this[routerList[i]] = false
            }
        },
    }
}
</script>
<style lang="less">
.custom-box-shadow{
    box-shadow: 0px 0px 1px 0px rgba(26, 32, 36, 0.32), 0px 4px 8px 0px rgba(91, 104, 113, 0.24);
}
.home-pc-nav{
    display: flex;
    width: 212px;
    padding: 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    background: #FFF;
    height:100vh;
    /* Nav Bar  */
    box-shadow: 3px 0px 9px 0px rgba(111, 111, 111, 0.07);
}
.app-main{
    flex:1;
}
.home-nav-list{
    display: flex;
    flex-direction: column;
    width:100%;
}
.home-nav-list-item{
    height: 40px;
    line-height: 40px;
    cursor:pointer;
    padding:0 24px;
    display: flex;
    align-items: center;
    margin-bottom:16px;
}
.home-nav-list-item:hover{
    border-radius: 8px;
  background: #E2FFFE;
}
.home-nav-list-item-active{
    border-radius: 8px;
    background: #E2FFFE;
}
.menu-img{
    width:24px;
    height:24px;
    margin-right:10px;
}
.app-main-header{
    display: flex;
    align-items: center;
    background:  #FFF;
    display: flex;
    width:calc(100% - 48px);
    height: 62px;
    padding: 13px 24px;
    justify-content: space-between;
    align-items: center;
    /* Dropshadow/Regular */
    box-shadow: 0px 3px 14px 3px rgba(0, 0, 0, 0.05);
    justify-content: space-between;
}
.app-main-header-right{
    display: flex;
    align-items: center;
}
.chain-text{
    color: var(--Dark-Gray-Dark-Gray-5, #48535B);
    text-align: justify;
    font-family: "Clash Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.224px;
    border-left:1px solid #BCBCBC;
    margin:0 16px;
    padding-left:16px;
}
.matebit-logo{

}
.footer-wallet-mobile-wrap{
    width: calc(100% - 32px);
    margin:0 auto;
}
.menu-text{
    color: #FFF;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
}
.invite-mobile-btn{
    width: calc(100% - 32px);
    height: 56px;
    display: flex;
    margin:16px auto;
    justify-content: center;
    align-items: center;
    background: #fff;
    color:#000;
    border-radius: 4px;;
    font-weight: 700;
}
.footer-mobile-address-warp{
    border-radius: 6px;
    border: 1px solid #D0D7DE;
    display: flex;
    align-items: center;
    margin-top:24px;
    flex-direction: column;
    color: #FFF;
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.footer-mobile-address-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 32px);
    margin:5px auto;
    padding:12px 0;

}
.copy-address-mobile-cur{
    color: #FFF;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
    margin-bottom:6px;
}
.footer-mobile-btn{
    color:  #CC0905;
font-family: "Noto Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: 0.084px;
text-transform: uppercase;
padding-bottom:12px;
}
.foot-warp-cur{
    border-top:1px solid #656565;
    display: flex;
    padding-top:12px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 32px);
    margin:5px auto;
}

.version{
    color: #4E4E4E;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.72px;
    padding-bottom:32px;
}
.about-header-back{
    width: 100%;
    height: 772px;
    position: absolute;
    top:0;
    left:0;
    background: url('@/assets/matebit-header.png') no-repeat;
    background-size: 100% 100%;
}
.about-header-back::after{
    content:'';
   width: 100%;
    height: 100%;
    flex-shrink: 0;
    position: absolute;
    top:0;
    left:0;

    background-size: 100% 100%;
    background: linear-gradient(180deg, rgba(13, 17, 22, 0.43) 0%, #0D1117 91.34%);
}
.about-header-excute::after{
   content:'';
   width: 100%;
    height: 100%;
    flex-shrink: 0;
    position: absolute;
    top:0;
    left:0;
    background: linear-gradient(180deg, rgba(13, 17, 22, 0.43) 0%, #0D1117 91.34%);
    background-size: 100% 100%;
}
.about-header-excute{
    width: 100%;
    height: 772px;
    position: absolute;
    top:0;
    left:0;
    background: url('@/assets/uno-ido-header.png') no-repeat;
    background-size: 100% 100%;
}
.select-wallet-left{
    display: flex;
    height: 38.5px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px 0 0 6px;
    border: 1px solid #9AA6AC;
    border-right: 0;
    // background: #00A3FF;
   color: #FFFFFF;
   cursor: pointer;
   position: relative;
}
.white-paper-mobile{
    display: flex;
    width: calc(100% - 56px);
    height: 48px;
    padding: 8px 12px;
    margin:12px auto 0;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 0px solid #00A3FF;
    background: #00A3FF;
    color:#fff;
}
.white-paper-mobile a{
    color:#fff;
    text-align: center;
    width:100%;

}
.down-arrow-white{
    width:24px;
    height: 24px;
    min-width: 24px;;
}
.infi-icon{
    min-width:16px;
    height: 16px;
}
.select-wallet{
    display: flex;
}
.select-wallet-right{
    display: flex;
    height: 38.5px;
    padding: 0px 9px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 0 6px 6px 0;
    border: 1px solid #9AA6AC;
    color: #fff;;
    cursor: pointer;
    margin-right:16px;
    position: relative;
}
.select-right-out{
    position: absolute;
    top: 50px;
    right: 0px;
    color:#00A3FF;
    cursor: pointer;
    border-radius: 8px;
    background: #0F0F16;
    padding: 16px;
}
.copy-address{
    white-space: nowrap;
    color: #fff;

/* Headings/Heading 14 Semi Bold */
font-family: "Noto Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: 0.084px;
text-transform: uppercase;
padding-bottom:16px;
}
.login-out{
    color:  #CC0905;

/* Headings/Heading 14 Semi Bold */
font-family: "Noto Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: 0.084px;
text-transform: uppercase;
}
.referral-textarea{
    width: 100%;
    background: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
    background: transparent;
    align-items: center;
    word-break: break-all;
    border: 0;
    resize: none;
    appearance: none;
}
.referral-textarea::-webkit-scrollbar{
 width: 0;
height: 0;
}
.extract-wrap{
    width: 100%;
}
.invite-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #141A23;
    padding:12px 16px;
    border-radius: 6px;
    width: calc(100% - 32px);
    color:#fff;
}
.your-invitee{
    margin-top:24px;
    margin-bottom:12px;
    color:  #FFF;
    font-family: "Noto Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.38px;
}
.your-Inviter{
    color: #FFF;
    font-family: "Noto Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.38px;
}
.select-left-drow{
    position: absolute;
    top: 46px;
    left: 0px;
    padding: 16px;
    /* width: 180px; */
    white-space: nowrap;
    color:#00A3FF;
    cursor: pointer;
    // padding:6px 0;
    border-radius: 8px;
    background: #0F0F16;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
    // border: 1px solid #00A3FF;
}
.claim-dialog{
    position: absolute;
    left:50%;
    top:50%;
    width:calc(900px - 24px);
    transform: translate(-50%,-50%);
    border-radius: 24px;
    padding: 24px;
    z-index:998;
    background: #0F0F16;
}
.claim-dialog-title{
    color:  #FFF;
    font-family: "Noto Sans";
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-top:48px;
    line-height: 56.816px; /* 129.128% */
    text-transform: uppercase;
}
.claim-dialog-main{
    display: flex;
    width: calc(100% - 48px);
    height: 104px;
    margin:48px auto;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding:0 24px;
    border-radius: 12px;
    background: #353548;
}
.claim-infi-text{
    color:  #FFF;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
}
.amount-input {
    // border: 1px solid #ced4da;
    outline: none;
    border:0;
    background:none;
    text-align: right;
    width: 100%;
    caret-color:#00A3FF;
    color:#fff;
}
.amount-input:focus{
    caret-color:#00A3FF;
    color:#00A3FF;
}
.amount-input::-webkit-input-placeholder {
    color:  #00A3FF;
    text-align: right;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.44px;
}
.collect-dialog-now{
    display: flex;
    width: 303px;
    height: 48px;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin:0 auto 48px;
    border-radius: 6px;
    background: #00A3FF;
    color:  #F6F8F9;
    text-align: center;
    /* Headings/Heading 18 Semi Bold */
    font-family: "Noto Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;
    cursor: pointer;
}
.header-close{
    position: absolute;
    right:20px;
    top:20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

nav a.contact-link-node {
    width: 137px;
    padding: 10px 10px;
    color: #000;
    font-weight: 600;
    background: linear-gradient(96.85deg, #51FFA8 1.03%, #00D147 101.26%);
    box-shadow: 0px 0px 11px #51FFA8;
    border-radius: 8px;
}
.hide{
    display: none;
}
.header-container-nav .logo-text{

    font-weight: bold;
    border-bottom:0!important;
    color:#fff!important;
    font-family: Faktum;
    font-size: 30.009px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;



}

.flex-content{
    flex:1;
    display: flex;
    justify-content: flex-end;
    margin-left: 100px;
}

.nav-cell-wrap a{
    margin-right:32px;
    color: #FFF;
    text-align: center;
    /* Headings/Heading 14 Semi Bold */
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;

    white-space: nowrap;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    cursor: pointer;
}
// .nav-cell-wrap a:hover{
//   color:#00A3FF
// }
.text-gray{
  position: relative;
}
// .text-gray:hover::after{
//   width: 100%;
//   left: 0;
//   right: unset;
//  }
//  .text-gray::after{
//   position: absolute;
//   right: 0;
//   bottom: -2px;
//   content: "";
//   display: block;
//   height: 1px;
//   width: 0;
//   background-color: #00A3FF;
//   transition: width .35s cubic-bezier(.22,.84,.48,.99);
//  }
.nav-cell-wrap-pc{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-line{

margin-left:20px;
margin-right:77px;
display: inline-block;
}
// .nav-cell-wrap-pc .router-link-active{
//     border-bottom:1px solid #fff;
// }
.bate-text {
    transform: scale(0.8);
    display: inline-block;
    margin-left: -10px;
}
.wallet-connect{
    color:#48535B;
    border:1px solid gray;

    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding:5px 16px;
    cursor: pointer;
    border-radius: 6px;



}
.invite-text{
    display: flex;
    height: 41px;
    padding: 0px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    border-radius: 6px;
    border: 0px solid #00A3FF;
    background:#fff;
    /* Headings/Heading 14 Semi Bold */
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    // line-height: 24px; /* 171.429% */
    // letter-spacing: 0.084px;
    text-transform: uppercase;
    cursor: pointer;
}
.select-wallet-mobile-wrap{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:12px;
    width: calc(100% - 32px);
    margin:0 auto 12px;

}
.select-wallet-mobile{
    display: flex;
    height: 41px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 6px;
    border: 1px solid #00A3FF;
    color: #00A3FF;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}
.invite-mobile{
    display: flex;
    // height: 48px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 6px;
    border: 0px solid #00A3FF;
    background: rgba(0, 163, 255, 0.30);
    color:#00A3FF;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}
.infi-claim-mobile{
    display: flex;
    width: calc(100% - 56px);
    height: 48px;
    padding: 8px 12px;
    margin:0 auto;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 0px solid #00A3FF;
    background: #00A3FF;
    color:#fff;
}
.infi-claim-mobile-left{
    display: flex;
    align-items: center;
    color: #FFF;
    /* Headings/Heading 18 Semi Bold */
    font-family: "Noto Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;
    img{
        margin-right:5px;
        object-fit: contain;
        width: 20px;
        height: 20px;
    }
}
.infi-claim-mobile-right{
    color: #FFF;
    /* Headings/Heading 14 Semi Bold */
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}
.drawer-body .line-mobile{
    width: calc(100% - 32px);
    margin:16px auto;
}
.foot-warp{
    display: flex;
    width: calc(100% - 32px);
    margin:0 auto;
}
.copy-address-mobile{
    padding: 8px 12px;
    margin:0 auto;
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 0px solid #00A3FF;
    background: #00A3FF;
    color:#fff;
    margin-right:16px;
}
.footer-mobile{
    display: flex;
    // width: calc(100% - 56px);
    flex:1;
    height: 48px;
    line-height: 48px;;

    padding: 8px 12px;
    margin:0 auto;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #F2271C;
    color:#fff;
    text-align: center;

    /* Headings/Heading 18 Semi Bold */
    font-family: "Noto Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;

}
.flex{
    display: flex;
}
* {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

#app {
    font-family: 'Noto Sans', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;

    position: relative;
    // background: rgb(11, 11, 15);
    background: #fff;
    height: 100%;
    display: flex;
    // color: #fff;

    .notice{
        min-height: 37px;
        background: #FFEE58;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 22;
        position: relative;
        .desc{
            min-height: 21px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #2B2E3D;
            line-height: 21px;
            margin-left: 13px;
        }
        button{
            width: 79px;
            min-height: 28px;
            background: #FF7D58;
            border: 0;
            border-radius: 3px;
            font-size: 13px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #fff;
            line-height: 20px;
            margin-right: 8px;
        }
    }
    .seamless-warp{
        height: 37px;
        background: #d6ffe9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //  border-top:1px solid #363636;
         margin: 0 auto;

        .item{
            // width: 200px;
            list-style: none;
            padding: 0;
            margin: 0 auto;
            display: flex;
        }
    }
}

img {
    max-width: 100%;
    vertical-align: middle;
}

ul {
    list-style: none;
}

nav {
    position: sticky;
    padding: 0 120px 0 150px;
    display: flex;
    justify-content: space-between;
    height: 95px;
    top: 0;
    align-items: center;
    z-index: 1000;

    .nav_div {
        width: 700px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .logo {
        height: auto;;

        object-fit: contain;
    }

    a {
        display: inline-block;
        color: #fff;
        position: relative;
        text-decoration: none;
        font-size: 14px;
        margin: 0 10px;
        white-space: nowrap;


        //活跃时的样式
        &.router-link-exact-active {
            color: #00A3FF;
            transition: all linear 150ms;

            &::after {
                content: ' ';
                position: absolute;
                left: 0;
                bottom: -4px;
                width: 100%;
                height: 2px;
                background-color: #00A3FF;
            }
        }

        &.contact-link {
            color: #fff;
            background: #51FFA8;
            color: #000;
            border-radius: 10px;
            padding: 10px 10px;
            font-weight: bolder;
            margin: 0;

            &::after {
                content: unset;
            }
        }
    }
}

.mobile-nav {
    // display: none;
    position: fixed;
    top:24px;
    left:60px;
    z-index: 996;
    border-radius: 50px;
    border: 1px solid  #00FFE5;
    background: rgba(0, 146, 130, 0.05);
    backdrop-filter: blur(12px);
    width:calc(100% - 120px);
    margin:24px auto;

    .header-container-nav {
        padding: 24px 60px;

        display: flex;
        align-items: center;
        justify-content: space-between;


        .logo {
            width: 204px;
            margin-right:5px;
            height: 32px;
        }

        .right {
            display: flex;
            // gap: 18px;
            // width: 675px;
            .language {
                display: flex;
                align-items: center;
                gap: 4px;
                position: relative;
                cursor: pointer;
                button {

                    font-family: BaiJamjuree-Medium, BaiJamjuree;
                    font-weight: 500;
                    color: #fff;
                    line-height: 28px;
                    background: transparent;
                    border: 0;

                }
            }

        }


        .down {
            width: 30px;
            height:22px;
        }

        .icon-block {
            .iconfont {
                font-size: 18px;
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }

    .languageWraper {
        position: absolute;
        bottom: 0;
        right: 0;
        // height: 100vh;
        width: 210px;
        z-index: 99;
        display: flex;
        justify-content: flex-end;

        .drawer-model {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .langList {
            width: 180px;
            // height: 230px;
            position: absolute;
            right: 0;
            top: 10px;
            padding:5px 16px;
            background: rgba(26, 29, 40, 0.7);
            box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(4px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            .lanItem {
                font-size: 20px;
                font-family: BaiJamjuree-Regular, BaiJamjuree;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;

            }

            .lanItemHover {
                color: #1DD79B;
            }
        }
    }


}


.icon-menu {
    width: 32px;
    height: 32px;
    background: url('@/assets/icon-menu-white.png') no-repeat;
    background-size: 100% 100%;
}

.drawer-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99;
    display: flex;
    justify-content: flex-end;

    .drawer-model {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .drawer-container {
        max-width: 210px;
        height: 100%;
        width: calc(100vw - 75px);
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        position: relative;

        background-color: rgba(26, 28, 40, .8);
        backdrop-filter: blur(5px);
        z-index: 997;


        z-index: 1;

        .drawer-header {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 10px;
            margin-top: 6px;

            .arrow-right {
                width: 25px;
                height: 25px;
                background-image: url("@/assets/home/close-white.png");
                background-size: 25px 25px;
                background-repeat: no-repeat;
            }

            .icon-block {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                .iconfont {
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }

        .drawer-body {
            // padding: 14px 20px;
            height:100%;
            max-height: calc(100% - 60px);
            overflow-y: scroll;

            .nav-cell {
                align-items: center;
                justify-content: center;
                flex-direction: column;

                height: auto !important;

                .link-item {
                    line-height: 19px;
                }

                .drop-dropdown-menu {
                    position: relative !important;
                    height: auto !important;
                    top: 0 !important;
                    padding-top: 8px;
                    background: transparent !important;
                    border-radius: 0 !important;
                    display: flex;
                    grid-row-gap: 8px;
                    flex-direction: column;

                    .drop-dropdown-menu-item {
                        line-height: 19px !important;
                        height: auto !important;
                        padding: 0 0 0 8px !important;
                        // color: #ffffff !important;

                        &.router-link-active {
                            // background:#090A0E;
                            // color: #fff !important;
                        }
                    }

                }

                .whitePaper {
                    width: calc(100% - 40px);
                    height: 40px;
                    background: #51FFA8;
                    border: 0px;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    font-family: BaiJamjuree-Medium, BaiJamjuree;
                    font-weight: 500;
                    color: #2B2E3D;
                    line-height: 40px;
                    margin:33px auto;
                    padding-left:0;
                }
            }

        }

        .login-cell {
            margin-top: 8px;

            .button {
                position: relative;
            }

            .login-link {
                padding: 0;
                background-color: transparent;
                border-radius: 0;
                line-height: 19px;
                font-size: 14px;
                font-family: BaiJamjuree-Regular;
                color: #ffffff;
                border: 0;
            }

        }
    }
}

@media screen and (max-width: (9999px - 1px)) {
    nav {
        /* transform: scale(0.5); */
        padding: 0;
        /* margin-left: -100px; */
        width: calc(100% - 40px);
        margin: 0 auto;
        overflow: auto;
    }

    nav .logo {
        display: none;;
    }

    nav::-webkit-scrollbar {
        width: 0;;
    }

    .nav-cell {
        display: flex;
        font-weight: 500;

        a {

            font-size: 14px;
            min-width: calc(100% - 30px);
            min-height: 54px;
            line-height: 54px;
            display: block;
            color: #fff;
            padding-left:20px
        }

        .disabled {
            color: #8C8D92;
        }


    }

    .header-container-nav  .router-link-active {
        background: transparent;
    }
    .router-link-active {
        // background:#090A0E;
        color: #fff !important;
        // border-bottom:1px solid #fff;

    }

}
@media screen and (max-width: (768px - 1px)) {
    .nav-cell .dropDown-item-mobile{
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;

    // width:38px;
    // height: 38px;
 }
 .flex-pc{
    display: none;
 }
 .dropDown-list-mobile{
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background: #141A23;
    color: #FFF;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
 }
 .router-box-img-mobile{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content:center;
    align-items: center;
    border-radius: 8px;
    background: #2B384B;
    margin-right:12px;
 }

 .about-header-back{
    height: 390px;;
    background: url('@/assets/matebit-header-mobile.png') no-repeat;
    background-size: 100% 100%;
}

.about-header-excute{
    height: 297px;;
    background: url('@/assets/uno-ido-header-mobile.png') no-repeat;
    background-size: 100% 100%;
}
.mobile-nav{
    width:calc(100% - 48px);
    left:24px;
}

    .mobile-nav .header-container-nav {
        padding: 16px 16px;
    }
    .mobile-nav .header-container-nav .down {
        width: 30px;
            height:22px;
        font-size: 20px;
    }
    .flex-content{
        display: none;
    }
    .drawer-wrapper .drawer-container{
        width: 100%;
        max-width:100%;
        // min-height: 650px;
        height: fit-content;;
    }

    .logo-infinimos{
        color:#fff;
        a{
            color:#fff!important;
            border:0!important;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
        }
         .logo{


                width:20px;
                height: 20px;
                object-fit: contain;



        }
    }
    .drawer-body .nav-cell a{
        margin-bottom: 24px;
         min-height: auto;

        line-height: 56px;
        width: calc(100% - 32px);
         padding-left:0;

       margin:0 auto;
        align-items: center;
        height: 56px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #232323;


    }

    .icon-menu {
        width: 32px;
        height: 32px;
        background: url('@/assets/icon-menu-white.png') no-repeat;
        background-size: 100% 100%;
    }
    .mobile-nav  .languageWraper {
        position: absolute;
        bottom: 0;
        right: 0;
        // height: 100vh;
        width: 120px;
        z-index: 99;
        display: flex;
        justify-content: flex-end;

        .drawer-model {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .langList {
            width: 120px;
            height: 230px;
            position: absolute;
            right: 0;
            top: 10px;
            background: rgba(26, 29, 40, 0.7);
            box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(4px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            .lanItem {
                font-size: 20px;
                font-family: BaiJamjuree-Regular, BaiJamjuree;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;

            }

            .lanItemHover {
                color: #1DD79B;
            }
        }
    }
    .drawer-wrapper .drawer-container .drawer-header .arrow-right {
        width: 25px;
        height: 25px;
        background-image: url("@/assets/home/close-white.png");
        background-size: 25px 25px;
        background-repeat: no-repeat;
    }
    .drawer-wrapper .drawer-container .drawer-header  .icon-block {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconfont {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.5);
        }
    }
    .drawer-wrapper .drawer-container .drawer-header{
        height:40px;
        justify-content: space-between;
        color:#fff;
        width: calc(100% - 32px);
        margin:24px auto;
    }

    .wallet-connect{
        margin-left:5px;
    }
    .langList {
        width: 114px;
        height: 230px;
        position: absolute;
        right: 0;
        top: 64px;
        background: rgba(26, 29, 40, 0.7);
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(4px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .lanItem {
            font-size: 16px;
            font-family: BaiJamjuree-Regular, BaiJamjuree;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;

        }

        .lanItemHover {
            color: #1DD79B;
        }
    }

}
</style>
<style lang="less" scoped>
.position-com{
  position: relative;
}
.dropDown-list{
    position: absolute;
    top: 45px;
    left: 0;
    // width: 100px;
    padding:16px;
    border-radius: 16px;
    background: #141A23;
    // border:1px solid #191B25;
    z-index:111;
}
.dropDown-list::before {
  content: "";
  position: absolute;
  top: -19px;
  left: 30px;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #141A23 transparent;
}
.dropDown-item{
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom:16px;
    // justify-content: center;
}
.dropDown-item-active{
    color: #ED59F1;
}
 .about-router{
    display: flex;
    align-items: center;
 }
 .arrow-route-down{
    width:16px;
    height: 16px;
    cursor: pointer;
    margin-left:5px;
 }
 .router-box-img{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:12px;
    border-radius: 8px;
    background: #2B384B;
 }
 .router-img{
    width: 24px;
    height: 24px;
 }
  .extract-dialog-warp-super{
    border-radius: 6px;
    background-color:transparent;
}

   .extract-dialog-warp-super{
   ::v-deep{
        .van-field:focus {
            border-color: #51FFA8;
        }

        .custom-empty-message{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
        }
        .no-date-img{
            width:98px;
            height:64px;
            margin-bottom:17px;
        }

        .dialog-close{
            position: absolute;
            right:0px;
            top:0px;
            width: 40px;
            height: 40px;
        }
        .extract-dialog{
            margin-top:20px;
            border-radius: 6px;
            // height: 334px;
            padding-bottom:5px;
            background-color: #2B2E3D ;
        }
        .dialog-title{
            width:56px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:0px auto 9px;
            padding-top:22px;
        }
        .dialog-title-img{
            width: 100%;
            height: 100%;
        }
       .extract-input-box{
             width: 259px;
            margin:26px auto 25px;
            border-radius: 6px;
            // border: 1px solid #51FFA8;
        }
        .extract-input-box .van-field__control {
            color: #fff;
        }
        .extract-input-box .van-cell{
            background-color: #191B25 ;
            border-radius: 6px;
        }
        .nav-dialog{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                margin: 0px 8px;
            }
        }
        .extract-dialog-warp{
            min-height: 340px;
            width: calc(100% - 5px);
        }

        .max-text{
            width: 36px;
            height: 21px;
            font-size: 17px;
            font-family: BaiJamjuree-SemiBold, BaiJamjuree;
            font-weight: 600;
            color: #51FFA8;
            line-height: 21px;
            padding-left:17px;
            border-left: 1px solid #e1e1e1cc;
        }

        .extract-dialog-title{
            text-align: center;
            font-size: 22px;
            font-family: BaiJamjuree-SemiBold, BaiJamjuree;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 28px;
            margin-bottom:26px;
        }
        .usdtAmount{
            display: flex;
            color: #ffffff;
            margin-top: -18px;
            margin-left: 35px;
            font-size: 12px;
        }
        .err-extract-text{
            display: flex;
            color: red;
            margin-top: 8px;
            font-size: 12px;
            margin-left: 35px;

        }
        .Authorize-btn{
            width: 259px;
            height: 48px;
            background: #51FFA8;
            border: 0px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:10px auto 30px;
            font-size: 17px;
            font-family: BaiJamjuree-SemiBold, BaiJamjuree;
            font-weight: 600;
            color: #191B25;
            &:disabled{
                background: #8C8D92;
            }
        }
    }

   }
    .error-extract-input-box{
        border:1px solid red!important;
    }
    ::v-deep{
    .extract-input-box .van-field__control {
        // color: #fff;
    }
    .extract-input-box .van-cell{
        background-color: #2B2E3D ;
        border-radius: 6px;
        height: 42px;
        max-height:42px;
    }
    .van-field__value{
        max-height:42px;
    }
    .pledge-vant-tabs .van-tabs__nav{
        background: transparent;
    }
    .pledge-vant-tabs .van-tab--active {
        color:#51FFA8;
        font-size: 18px;
        font-family: BaiJamjuree-SemiBold, BaiJamjuree;
        font-weight: 600;

    }
    .pledge-vant-nav-tabs .van-tab--active {
        color:#00A3FF;
        font-size: 12px!important;
        font-family: BaiJamjuree-SemiBold, BaiJamjuree;
        font-weight: 500;

    }
    .pledge-vant-tabs .van-tab {
        color: #00A3FF;
    }
    .pledge-vant-nav-tabs  .van-tab {
        font-size: 12px;
    }
    .custom-amount{
        font-size: 14px;
        font-family: BaiJamjuree-Regular, BaiJamjuree;
        font-weight: 400;
        // color: #00A3FF;
        line-height: 18px;
    }
    .pledge-vant-tabs .van-tabs__line {
        background-color: #00A3FF;
    }
    .extract-wrap table.vgt-table{
        background-color: #262836;
    }
    .extract-wrap .vgt-table thead th{
        background:#0F0F16;
        opacity: 0.8;
        font-size: 14px;
        white-space: nowrap;

        color: #fff;
       padding-left:16px;
       font-family: "Noto Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.252px;
    }
    .extract-wrap  .custom-status{
        color:#22C348!important;
    }
    .extract-wrap  .custom-status-no{
        color:#F2271C
    }
    .extract-wrap table.vgt-table{
        // border: 1px solid #51FFA8;
        // border-radius: 10px;
        border:none;
    }
    .extract-wrap .vgt-wrap__footer{
        background: #262836;
        border:none;
        // border-top:1px solid #51FFA8;
        font-size: 12px;
        border-radius: 9px;
    }
    .extract-wrap  .vgt-table th.sortable button:before{
        border-top: 0.26042rem solid #fff;
        cursor: pointer;
    }
    // .extract-wrap .vgt-inner-wrap{
    //         height: 400!important;;
    //         max-height: 400px!important;
    //         overflow: scroll!important;;
    //      }
        //  .extract-wrap{
        //     max-height: 400px; /* 设置表格容器的最大高度 */
        //     overflow-y: auto; /* 控制垂直方向的滚动条 */
        //  }
         .extract-wrap::-webkit-scrollbar{
            width: 0;
            height: 0;;
            background-color: transparent; /* 设置滚动条轨道的背景颜色为透明 */
         }
    .extract-wrap .vgt-table th.sortable button:after{
        border-bottom: 0.26042rem solid #fff;
        cursor: pointer;
    }
    .vgt-responsive{
        border-radius: 9px;
        //  border-radius: 9px 9px 0 0;
    }
    .extract-wrap-super  .vgt-responsive{
        border-radius:9px 9px 0 0;
    }
   .extract-wrap .vgt-inner-wrap{
    box-shadow:none;
   }
   .extract-wrap .vgt-table th.sortable button{
    position: relative;
    top: -4px;
    left: 20px;
    width: auto;
    height: auto;
   }
    .custom-empty-message{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        margin:10vh auto;
    }
    .no-date-img{
        width:98px;
        height:64px;
        margin-bottom:17px;
    }
    .extract-wrap .vgt-table.bordered td, .vgt-table.bordered th{
        border:none;
        font-size: 12px;
        color: #FFFFFF;
    }

    .extract-wrap .vgt-table td{
      padding-left:0.8rem;
      font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    }

    .extract-wrap tbody tr{
        border-bottom: 1px solid  #252C32;
        background: #0F0F16;
    }
    .extract-wrap tbody tr:last-child{
        border-bottom:0;
    }
    .custom-col-content{
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.084px;

    }



.extract-dialog-warp .van-cell{
  background-color: #191B25 ;
  border-radius: 6px;
}
    }
</style>

